import React, { useEffect, useState } from "react";
import css from './Home.module.css'
import { get } from "../../a_model/fetch";
import { RankingBar } from "../../a_model/Contents";
import { toCash, toDateMonth } from "../../a_model/functions";

export default function Dashboard(){
    const [data, setData] = useState([])
    const [max, setMax] = useState({count: 0, max: 0})
    useEffect(() => {
       get(`/dashboard`, then)
      }, [])
    
    function then(data){
        setData(data)
        const extractTotal = data.reduce((max, current) => {
            return current.total > max ? current.total : max;
        }, 0);
        const extractCount = data.reduce((max, current) => {
            return current.valor > max ? current.valor : max;
        }, 0);

        setMax({valor: extractCount, total: extractTotal})
    }
    
    return(
        <div id={css.dashboard}>
            <RankingBar>
            {data.map((item, index) =>
                           item &&
                            <li key={index}>
                                <span>
                                {toDateMonth(item.data)}
                                </span>
                                <div 
                                style={{ 
                                    background:'#7238a1',
                                    height: (item.total/max.total)*100+"%"}}>
                                <span>
                                {item.total} 
                                </span>
                                </div>
                                <div 
                                style={{ 
                                    height: (item.valor/max.valor)*100+"%"}}>
                                <span style={{fontSize: '12px'}}>
                                {toCash(item.valor)} 
                                </span>
                                </div>
                                
                            </li>
                        )}
            </RankingBar>
        </div>
    )
}