import React, { useState, useEffect, useMemo } from "react";
import {  Header } from "../../a_model/Header";
import { InputSearch, LabelText } from "../../a_model/Input2";
import { Context } from "../e_contexts/AuthContext";
import { Page } from "../../a_model/Container";
import { getTheme } from "../../a_model/Theme";
import { useContext } from "react";
import copy from 'copy-to-clipboard'
import { CadastroCliente } from "../b_forms/CadastroCliente";
export default function Clientes() {

    
    const [clientes, setClientes] = useState([])
    const [cliente, setCliente] = useState({})
    const [ setProject] = useState([])
    const { params } = useContext(Context)
    useEffect(() => {
        console.log(params)
     }, [])
   
    const [currentFicha, setCurrentFicha] = useState(0)
    const navList = ['Cadastro']
    const steps = [
    <CadastroCliente/>
       
    ]

    return (    
            <Page>
                <Header
                     setStep={setCurrentFicha}
                    navList={navList}
                    buttons={[]}
                >
                   {cliente.nome ?
                        <div style={{background: 'transparent'}}>
                            <LabelText
                                color='#fff'
                                svg='1'
                                header='Cliente:'
                                onClick={() => setCliente({})}
                            >
                                {cliente.nome}
                            </LabelText>
                            <LabelText
                                color='#fff'
                                svg='0'
                                header='Código:'
                                onClick={() => copy(cliente.id)}

                            >
                                {cliente.id}
                            </LabelText>

                        </div>

                        :

                        <ClientesBar
                            setCliente={setCliente}
                            clientes={clientes}
                        />
                    }
                </Header>
                <body >
                    {steps[currentFicha]}
                </body>
            </Page>
    )
}

function ClientesBar({ clientes, setCliente }) {
    const [search, setSearch] = useState('');
    const results = useMemo(() => {

        const lowerBusca = search.toLowerCase()
        return clientes.filter(user => user.nome ? (user.nome.toLowerCase().includes(lowerBusca) || user.telefone.includes(lowerBusca) || String(user.id).includes(lowerBusca)) : '');


    }, [search])
    return (
        <InputSearch
            width='350px'
            search={search}
            setSearch={setSearch}>

            <ListItem
                search={search}
                setCliente={setCliente}
                results={results}
            />
        </InputSearch>
    );
}

function ListItem({ results, setCliente, search }) {
    function formatTel(telefone){
        let retorno = String(telefone.substr(2, telefone.length-1).split(/[()-]/))   
        return `(${retorno.substr(0,2)}) ${retorno.substr(2,retorno.length-6)}-${retorno.substr(-4, 4)}`
    }
    return (
        search.length > 2 &&
        <>
            <ul >
                {results.map((result, index) => (

                    <li key={index} onClick={() => setCliente(result)}>
                        <span>{result.nome || '-'}</span>{result.telefone && <span>{formatTel(result.telefone)}</span>}
                    </li>

                ))}
            </ul>
            
        </>
    );
}