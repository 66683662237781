import React, { useState } from "react";
import './Inicio.css'
import { InputText, Select } from '../a_model/Input'
import { insert } from "./fetch";
import { MdOutlineKeyboardReturn } from "react-icons/md";

export default function Cadastrar({setStep, setId_copinha}) {
    const [project, setProject] = useState([
        { gamer: '', time: '' },
        { gamer: '', time: '' },
        { gamer: '', time: '' },
        { gamer: '', time: '' },
    ])
    const jogadores = [
        { id: '0', nome: 'Fellipe' },
        { id: 1, nome: 'Mamute' },
        { id: 2, nome: 'Batino' },
        { id: 3, nome: 'Luquinha' },
        { id: 4, nome: 'Hiaguim' },
    ]

    function handleChange(e, index) {
        const updatedProject = [...project];
        updatedProject[index] = { ...updatedProject[index], [e.target.name]: e.target.value };
        setProject(updatedProject);
    }
    function then(resp){
        setId_copinha(resp)
        setStep(2)
    }


    return (
        <div id='copinhaContainer'>
             <div id='voltar' onClick={() => setStep(0)}>
            <MdOutlineKeyboardReturn/>
            </div>
            {project.map((item, index) =>
                <div style={{ display: 'flex' }} key={index}>
                    <Select
                        padrao='Jogador'
                        options={jogadores}
                        value={item.gamer}
                        name='gamer'
                        handleOnChange={(e) => handleChange(e, index)}
                    />
                    <InputText
                        title='Time'
                        value={item.time}
                        name='time'
                        handleOnChange={(e) => handleChange(e, index)}
                        onBlur={() => {}}
                    />
                </div>
            )}
            <button onClick={()=> insert(`/cadastrarCopinha`,project, then)}>
                Começar
            </button>
        </div>
    )
}


/*setSalas(produce(resp3, draft => {
                    for (let i in resp2) {
                        draft.splice(resp3.find((element) => element.id == resp2[i].chave), 1)
                    }
                }))*/