import { createContext, useState, useEffect, useContext } from "react";
import { api } from "../hooks/useApi";

import Login from "../a_pages/Login";

const Context = createContext();

function AuthProvider({ children }) {
    const [verif, setVerif] =useState(false)
    const [loading, setLoading] = useState(true)
   const [params, setParams] = useState({})
    useEffect(()=>{
        const token = localStorage.getItem('token')
        if(token){
            api.defaults.headers.Authorization = `Bearer ${(token)}`
         //   console.log((token))
            setVerif(token)
        }
        setLoading(false)
        setParams({
        login:localStorage.getItem('login'),
        nome:localStorage.getItem('nome'),                
        tema:localStorage.getItem('tema'),        
        cargo:localStorage.getItem('cargo'),
        empresa:localStorage.getItem('empresa'),
        })
    }, [])

    async function handleLogin(login){
        
        const { data: {token} } = await api.post(`verif`)
        api.defaults.headers.Authorization = `Bearer ${token}`;
        localStorage.setItem('token', JSON.stringify(token))        
        setVerif(true)
        localStorage.setItem('login', login.login)
        localStorage.setItem('nome', login.nome)                
        localStorage.setItem('tema', login.tema)
        localStorage.setItem('empresa', login.empresa)        
        localStorage.setItem('cargo', login.cargo)
       window.location.replace('/app/home')
      
    }

    function handleLogout(){
        api.defaults.headers.Authorization = undefined;
        localStorage.removeItem('token')
        localStorage.removeItem('login')
        localStorage.removeItem('nome')                
        localStorage.removeItem('tema')  
        localStorage.removeItem('empresa')              
        localStorage.removeItem('cargo')
        setVerif(false)
        window.location.replace('/app/login')       
    }

    if (loading){
        return <h1>Loading...</h1>
    }

    return(
        <Context.Provider value ={{params, verif, handleLogin, handleLogout}}>
            {verif ? children :
            <Login/>}
        </Context.Provider>
    )
}

export { Context, AuthProvider}