import AForm from "./steps/AForm"
import BForm from "./steps/BForm"
import CForm from "./steps/CForm"
import { useContext, useEffect, useState } from 'react'
import styles from "./EditGeral.module.css"
import { Button } from '../../a_model/Input'
import { Context } from "../e_contexts/AuthContext"
import { get, update } from "../../a_model/fetch"


function RecordForm() {
  const { iduser, setStep } = useContext(Context)
  const [data, setData] = useState(false);
  useEffect(() => {
    get(`/record/${iduser}`, then)

  }, [])
  function then(resp) {
    setData({ ...resp, ['inicio']: resp.inicio.substr(0, 10) })

  }
  function handleChange(e) {
    setData({ ...data, [e.target.name]: e.target.value })

  }
  const updateField = (key, value) => {
    setData((prev) => {
      return { ...prev, [key]: value }
    })

  }

  function editRecord(id, cadastro) {
    function then() {
      setStep(1)
    }
    let record = cadastro
    if (cadastro.inicio) { record.inicio = cadastro.inicio.substr(0, 10) }
    update(`/update2/${id}`, record, then)
  }

  return (

    <div className={styles.formContainer}>
      <AForm data={data} handleChange={handleChange} updateField={updateField} />

      <BForm data={data} handleChange={handleChange} updateField={updateField} />

      <CForm data={data} updateField={updateField} />
      <footer>
        <Button
          color='#213e6d'
          value='Alterar'

          click={() => editRecord(data.id, data)}
        />
        <Button
          color='#b62f1d'
          value='Cancelar'
          click={() => setStep(1)}
        />
      </footer>
    </div>
  )
}

export default RecordForm