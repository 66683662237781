import React, { useEffect, useMemo, useState } from 'react';
import './Record.css';
import logo from '../../img/logop.png'
import { Page } from '../../a_model/Container';
import { Header } from '../../a_model/Header';
import { Select, InputDate } from '../../a_model/Input';
import { Context } from '../e_contexts/AuthContext';
import Controls from '../b_forms/Controls';
import moment from 'moment';


export default function Lancamentos() {

    const [params, setParams] = useState({ data: moment().subtract(7, 'days').format("YYYY-MM-DD"), data_fim: moment().format("YYYY-MM-DD"), confirm: undefined })
    const [step, setStep] = useState(0)

    const navList = [
        'Lançamentos'
    ]
    const confirmFilter = [

        { id: '0', nome: 'Pendentes' },
        { id: 1, nome: 'Confirmados' },
        { id: undefined, nome: 'Todos' }

    ]
    function handleChange(e) {
        setParams({ ...params, [e.target.name]: e.target.value })

    }

    return (
        <Context.Provider value={{
            params
        }}>
            <Page>
                <Header
                    setStep={setStep}
                    navList={navList}
                    buttons={[]}
                >
                    <img src={logo} className="logo" alt="Logo" />
                    <InputDate
                        name='data'
                        value={params.data}
                        handleOnChange={handleChange}
                    />
                    <InputDate
                        name='data_fim'
                        value={params.data_fim}
                        handleOnChange={handleChange}

                    />
                    <Select
                        padrao='Todos'
                        options={confirmFilter}
                        name='confirm'
                        value={params.confirm}
                        handleOnChange={handleChange}
                    />
                </Header>
                <body>
                    <Controls />
                </body>

            </Page>

        </Context.Provider >
    )

}

