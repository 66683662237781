import React, { useEffect, useState } from "react"
import { Table, Th } from '../../a_model/Contents'
import { get } from '../f_aux/fetch'

export function LancamentosForm({dialog}) {
    const [list, setList] = useState([])

    useEffect(() => {
        get(`/getVendas/1`, setList)

    }, [dialog])
    const pagamento = [
        { id: '0', nome: 'Pix' },
        { id: 1, nome: 'Débito' },
        { id: 2, nome: 'Crédito' },
        { id: 3, nome: 'Dinheiro' },
        { id: 4, nome: 'Outro' },
    ]
    const titles = [
        { title: 'Cliente' },
        { title: 'Valor' },
        { title: 'Data' },
        { title: 'Pagamento' },
    ]
    return (
        <div>
            <h2>
                Lançamentos
            </h2>
            <Table>
                <Th array={titles} />

                {
                    list.map((item) =>
                        <tr>
                            <td>
                                {item.nome}
                            </td>
                          
                            <td>
                                {parseFloat(item.valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                            </td>
                            <td>
                                {item.data.substr(0,10).split('-').reverse().join('/')}
                            </td>
                            <td>
                                { pagamento.find((element)=> item.pagamento == element.id).nome }
                            </td>
                        </tr>

                    )}

            </Table>


        </div>
    )

}
