import moment from "moment";

export function toCash(value) {
    let retorno;

    if (isNaN(parseFloat(value))) {
        retorno = (0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    } else {
        retorno = parseFloat(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }

    return retorno;
}

export function toDate(value) {
    let retorno = ''

    if (value) {
        retorno = (value).substr(0,10).split('-').reverse().join('/')
    } 

    return retorno;
}

export function toDateTime(value) {
    let retorno = ''
    

    if (value) {
        let data = value.substr(2,8).split('-').reverse().join('/')
        let hora = value.substr(11,5)
        retorno = `${data} ${hora}`
    } 

    return retorno;
}

export function toDateMonth(value) {
    let retorno = ''
    

    if (value) {
       
        retorno = moment(value).format('MMM/YY')
    } 

    return retorno;
}
