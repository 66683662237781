import React, { useEffect, useState } from "react"
import { InputText, InputCash, Button, InputNumber, Select } from "../../a_model/Input"
import { Table, Th } from '../../a_model/Contents'
import { get, insert } from '../f_aux/fetch'
import { Dialog } from "../c_layouts/Dialog";
import { RowForm } from "../../a_model/Container";

export function InsumosForm() {
    const [dialog, setDialog] = useState({ text: false, type: 0, att: Math.random() })


    return (
        <RowForm>
            <Dialog value={dialog}/>
            <Insumos dialog={dialog}/>
            <CadastroInsumo setDialog={setDialog} />

        </RowForm>

    )
}

function Insumos({dialog}) {
    const [list, setList] = useState([])

    useEffect(() => {
        get(`/getInsumos/1`, setList)

    }, [dialog])

    const titles = [
        { title: 'Nome' },
        { title: 'Tipo' },
        { title: 'Custo' },
        { title: 'Perda' },
        { title: 'Total' },
    ]
    return (
        <div>
            <h2>
                Lista de insumos
            </h2>
            <Table>
                <Th array={titles} />

                {
                    list.map((item) =>
                        <tr>
                            <td>
                                {item.nome}
                            </td>
                            <td style={{fontWeight:600, color:item.color}}>
                                {item.nome_tipo}
                            </td>
                            <td>
                                {parseFloat(item.custo).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                            </td>
                            <td>
                                {item.perda}%
                            </td>
                            <td>
                                { parseFloat(item.total).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }
                            </td>
                        </tr>

                    )}

            </Table>


        </div>
    )

}

function CadastroInsumo({ setDialog }) {
    const [tipos, setTipos] = useState([])

    useEffect(() => {
        get(`/getTiposInsumo`, setTipos)
    }, [])

    const [project, setProject] = useState({ nome: '', custo: 0, valor: 0 })
    const [statusButton, setStatusButton] = useState('Cadastrar')

    function handleChange(e) {
        setProject({ ...project, [e.target.name]: e.target.value })
    }

    function insertClient(cadastro) {
        if(cadastro.tipo != 100){
        cadastro.custo = cadastro.custo.split(',').join('.')

        setStatusButton('...')
        insert(`/insertInsumo`, cadastro, then)
        function then() {
            setDialog({ text: 'Cadastro Realizado!', type: 0, att: Math.random() })
            setStatusButton('Cadastrar')
            setProject({ nome: '', custo: 0, valor: 0 })
        }
    }else{
        window.alert('Selecione o tipo')
    }
    }


    return (

        <div height='500px'>
            <h2 >Cadastrar insumo</h2>

            <InputText
                width='400px'
                flex='column'
                title='Nome'
                value={project.nome || ''}
                name='nome'
                handleOnChange={handleChange}

            />
          

            <div style={{ display: 'flex' }}>

                <InputCash
                    placeholder='0,00'
                    width='100px'
                    flex='column'
                    title='Custo'
                    value={project.custo || ''}
                    name='custo'
                    handleOnChange={handleChange}

                />

                <InputNumber
                    flex='column'
                    title='Perda ( % ) '
                    placeholder='0'
                    name='perda'
                    value={project.perda || ''}
                    handleOnChange={handleChange}
                    width='80px'
                />
            </div>
            <Select
                options={tipos}
                style={{margin: '10px 0'}}
                name='tipo'
                padrao='Selecione o tipo de insumo'
                value={project.tipo}
                handleOnChange={handleChange}
            />

            <footer style={{ display: 'flex', width: '400px', justifyContent: 'space-around' }}>

                <Button
                    color='#295b9c'
                    click={() => insertClient(project)}
                    value={statusButton || '...'}
                    disabled={statusButton == 'Cadastrar' ? false : true}
                />
            </footer>
        </div>

    )
}