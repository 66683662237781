import React, { useEffect, useMemo, useState } from 'react';
import './Record.css';
import { useParams } from 'react-router';
import Geral from '../b_forms/Geral';
import Evo from '../b_forms/Evo';
import Control from '../b_forms/Control';
import RecordForm from '../b_forms/EditGeral'
import EditUser from '../b_forms/EditUser';
import ResumoForm from '../b_forms/Resumo';
import logo from '../../img/logop.png'
import { LogoutButton, LanButton, HomeButton } from '../d_inputs/LogoutButton';
import SupervisaoForm from '../b_forms/Supervisao';
import { Page } from '../../a_model/Container';
import { Header } from '../../a_model/Header';
import { get } from '../../a_model/fetch';
import { LabelText2, InputSearch } from '../../a_model/Input';
import { Context } from '../e_contexts/AuthContext';

export default function Record() {

    const [cliente, setCliente] = useState(false)
    const [clientes, setClientes] = useState([])
    const [step, setStep] = useState(0)
    useEffect(() => {
        get(`/records`, setClientes)
    }, [])
        
    const navList = [
        'Pessoal',
        'Geral',
        'Controle',
        'Evolução',
        'Resumo',
        'Supervisão'
    ]
    const forms = [
        <EditUser iduser={cliente? cliente.iduser : false}/>,
        <Geral />,
        <Control />,
        <Evo />, 
        <ResumoForm />,
        <SupervisaoForm />,
        <RecordForm />,    
    ]




    return (
        <Context.Provider value={{
            cliente, setCliente,
            setStep,
            iduser: cliente.iduser,
            idrecord: cliente.id
        }}>
            <Page>
                <Header
                    setStep={setStep}
                    navList={navList}
                    buttons={[]}
                >

                    <img src={logo} className="logo" alt="Logo" />
                    {cliente ?
                        <span>
                            <LabelText2
                                onClick={() => setCliente(false)}
                                header='Paciente:'>
                                {cliente.name}
                            </LabelText2>

                        </span>
                        :
                        <ClientesBar clientes={clientes} setCliente={setCliente} />
                    }
                </Header>
                <body>
                    {cliente ?
                        forms[step] :
                        'Selecione um paciente'}
                </body>

            </Page>

        </Context.Provider >
    )

}

function ClientesBar({ clientes, setCliente }) {
    const [search, setSearch] = useState('');
    const results = useMemo(() => {

        const lowerBusca = search.toLowerCase()
        return clientes.filter(user => user.name ? (user.name.toLowerCase().includes(lowerBusca) || String(user.id).includes(lowerBusca)) : '');


    }, [search])
    return (
        <InputSearch
            width='350px'
            search={search}
            setSearch={setSearch}>

            <ListItem
                search={search}
                setCliente={setCliente}
                results={results}
            />
        </InputSearch>
    );
}

function ListItem({ results, setCliente, search }) {

    return (
        search.length > 2 &&
        <>
            <ul >
                {results.map((result, index) => (

                    <li key={index} onClick={() => setCliente(result)}>
                        <span>{result.name || '-'}</span>
                    </li>

                ))}
            </ul>

        </>
    );
}