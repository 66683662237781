import React, { useEffect, useState } from 'react';
import { MdOutlineDelete } from 'react-icons/md'
import { AiOutlineEdit } from 'react-icons/ai'
import { BiEdit } from 'react-icons/bi'
import { Button, InputDate, InputText, TextArea, InputTime } from '../../a_model/Input'
import { Card } from '../../a_model/Container';
import { deletar, get, update } from '../../a_model/fetch';
import { toDate } from '../../a_model/functions';
import { Table, Th } from '../../a_model/Contents';

export default function SupervisaoCard({ sessions, getSessions, iduser }) {



    function InfoEdit({ session }) {

        const [edita, setEdita] = useState(0)

        const forms = [
            <InfoCard setEdita={setEdita} session={session} />,
            <EditCard setEdita={setEdita} session={session} getSessions={getSessions} />
        ]
        return (
            <div>
                {forms[edita]}
            </div>

        )
    }



    function Conceito() {
        const [sessionC, setSessionC] = useState(false)
        useEffect(() => {
           !sessionC &&
            getSessionC()
        }, [])
        const [editaC, setEditaC] = useState(0)
        function getSessionC() {
            setEditaC(0)
            get(`/conceitualizacao/${iduser}`, setSessionC)
         
        }
        const forms = [
            <InfoConceito setEditaC={setEditaC} sessionC={sessionC} />,
            <EditConceito setEditaC={setEditaC} sessionC={sessionC} getSessionC={getSessionC} />
        ]
        return (
            sessionC &&
            <div>
                {forms[editaC]}
            </div>
        )

    }
    return (

        <div className="sessoes">
            {sessions.map(session => {

                return (
                    <InfoEdit
                        session={session}
                    />
                )
            })}


        </div>
    )
}

function InfoCard({ session, setEdita, getSessions }) {
    function deleteSession(id) {

        var resp = window.confirm("Confirma a exclusão deste registro?");
        if (resp) {

            deletar(`/delete6/${id}`, getSessions)

        }
    }
    return (
        <Card
            header={
                <>
                    <label>Sessão: <label className="value"> {session.sessao}</label></label>
                    <label>Data: <label className="value"> {toDate(session.data)}</label></label>

                    <div

                        id={session.id}
                        onClick={() => setEdita(1)}

                    >
                        <BiEdit />
                    </div>
                    <div

                        id={session.id}
                        onClick={() => deleteSession(session.id)}

                    >
                        <MdOutlineDelete />
                    </div>

                </>
            }
        >
            <div className='session' key={session.id}>

                <label>Supervisão: <span className="value"> {session.supervisao}</span></label>

            </div>
        </Card>
    )
}

function EditCard({ session, getSession, setEdita }) {
    const [project, setProject] = useState({...session,  ['data']: session.data.substr(0,10)})

    function handleChange(e) {
        setProject({ ...project, [e.target.name]: e.target.value })

    }
    function edit(id) {
        update(`/update6/${id}`, project, getSession)
    }
    const titles2 = [
        { title: 'Sessão' },
        { title: 'Data' },
        { title: '*' },
        { title: '*' },
    ]

    return (
        <Card
            header={
                <>
                    <Table>
                        <Th array={titles2} />
                        <tr>
                            <td>
                                <InputText
                                    width='2.5em'
                                    value={project.sessao || ''}
                                    name='sessao'
                                    handleOnChange={handleChange}
                                />
                            </td>
                            <td>
                                <InputDate
                                    name="data"
                                    value={project.data}
                                    handleOnChange={handleChange}
                                />
                            </td>

                            <td>
                                <Button
                                    color='#2c61c4'
                                    value='Salvar'
                                    click={() => edit(session.id, project)}
                                />
                            </td>
                            <td>
                                <Button
                                    color='#554'
                                    value='Cancelar'
                                    click={() => setEdita(0)}
                                />
                            </td>
                        </tr>
                    </Table>
                </>
            }>
            <div style={{ width: '90%' }}>
                <TextArea
                    height='20vh'
                    name='supervisao'
                    value={project.supervisao || ''}
                    handleOnChange={handleChange}
                    title="Supervisão"
                />

            </div>
        </Card>
    )

}

function InfoConceito({ sessionC, setEditaC }) {

    return (
        <Card>
            <div className='session' key={sessionC.id}>

                <label>Nome Fictício: <span className="value"> {sessionC.nome_ficticio}</span></label>
                <label>Estrutura: <span className="value"> {sessionC.estrutura}</span></label>
                <label>Conceitualização: <span className="value"> {sessionC.text}</span><div

                    id={sessionC.id}
                    onClick={() => setEditaC(1)}

                >
                    <BiEdit />
                </div></label>

            </div>
        </Card>
    )
}

function EditConceito({ sessionC, getSessionC, setEditaC }) {
    const [project, setProject] = useState(sessionC)

    function handleChange(e) {
        setProject({ ...project, [e.target.name]: e.target.value })

    }
    function edit() {
        update(`/update7`, project, getSessionC)
    }
    const titles2 = [

        { title: '*' },
        { title: '*' },
    ]

    return (
        <Card
            header={
                <>
                    <Table>
                        <Th array={titles2} />
                        <tr>
                            <td>
                                <Button
                                    color='#2c61c4'
                                    value='Salvar'
                                    click={() => edit(sessionC.id, project)}
                                />
                            </td>
                            <td>
                                <Button
                                    color='#554'
                                    value='Cancelar'
                                    click={() => setEditaC(0)}
                                />
                            </td>
                        </tr>
                    </Table>
                </>
            }>
            <div style={{ width: '90%' }}>
                <InputText

                    name='nome_ficticio'
                    value={project.nome_ficticio || ''}
                    handleOnChange={handleChange}
                    title="Nome Fictício"
                />
                <TextArea

                    name='estrutura'
                    value={project.estrutura || ''}
                    handleOnChange={handleChange}
                    title="Estrutura"
                />
                <TextArea

                    name='text'
                    value={project.text || ''}
                    handleOnChange={handleChange}
                    title="Conceito"
                />

            </div>
        </Card>
    )

}