import React, { useContext, useState } from "react"
import { InputText, Checkbox, Button, InputMasks } from "../../a_model/Input2"
import { insert } from '../f_aux/fetch'
import { Context } from "../e_contexts/AuthContext";
import { Dialog } from "../c_layouts/Dialog";

export function CadastroCliente({ abrir }) {

    const { params } = useContext(Context)
    const [project, setProject] = useState({ empresa: params.empresa, nome: '', telefone: '55', cpf: '', cep: '', email: '', valor: 0.0 })
    const [statusButton, setStatusButton] = useState('Cadastrar')
    const [dialog, setDialog] = useState({ text: false, type: 0, att: Math.random() })
    function handleChange(e) {
        setProject({ ...project, [e.target.name]: e.target.value })
    }

    function insertClient() {
        setStatusButton('...')
        let project2 = project
        project2.telefone = project.telefone.replace(/[^0-9]/g, '')

        insert(`/insertCliente`, project2, then)
        function then(resp) {
            setDialog({ text: 'Cadastro Realizado!', type: 0, att: Math.random() })
            setStatusButton('Cadastrar')
            setProject({ empresa: params.empresa, nome: '', telefone: '55', cpf: '', cep: '', email: '', valor: 0.0 })
        }
    }

    return (

        <div height='500px'>
            <h2 >Novo Cliente</h2>
            <Dialog
                value={dialog}
            />
            <InputText
                width='400px'
                flex='column'
                title='Nome'
                value={project.nome || ''}
                name='nome'
                handleOnChange={handleChange}

            />

            <InputMasks
                flex='column'
                mask='2'
                title="Telefone"
                name="telefone"
                value={project.telefone}
                handleOnChange={handleChange}
            />
            <InputText
                width='400px'
                flex='column'
                title='Email'
                value={project.email || ''}
                name='email'
                handleOnChange={handleChange}

            />
            <InputText
                width='400px'
                flex='column'
                title='CPF'
                value={project.cpf || ''}
                name='cpf'
                handleOnChange={handleChange}

            />


            <footer style={{ display: 'flex', width: '400px', justifyContent: 'space-around' }}>

                <Button
                    color='#295b9c'
                    click={() => insertClient()}
                    value={statusButton || '...'}
                    disabled={statusButton == 'Cadastrar' ? false : true}
                />
            </footer>
        </div>

    )
}
