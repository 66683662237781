import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Inicio from './copinha/Inicio';
import App2 from './App2';
import App3 from './App3';

function App() {
  // <Route exact path='/barbearia/:empresa/:user' element={<Barbearia />} />
       
  return (
    <Router>
      <Routes>
        <Route exact path='/copinha' element={<Inicio />} />
        <Route exact path='/app/*' element={<App3 />} />
        <Route path='/*' element={<App2 />} />
      </Routes>
    </Router>
  );
}

export default App;
