import React, { useEffect, useState } from "react";
import './Inicio.css'
import { get, insert, update } from "./fetch";
import { Table, Th } from "../a_model/Contents";
import { Button, Select } from "../a_model/Input";
import { HiOutlineRefresh } from "react-icons/hi";
import { MdOutlineKeyboardReturn } from "react-icons/md";

export default function Tabela({ id_copinha, setStep }) {
    const [tabela, setTabela] = useState({ jogos: [], tabela: [] })

    const [refresh, setRefresh] = useState(false)
    useEffect(() => {
        id_copinha &&
            get(`/getTabela/${id_copinha}`, setTabela)
    }, [refresh])

    const titles = [
        { width: '', title: 'Time' },
        { width: '', title: 'Pts' },
        { width: '', title: 'GF' },
        { width: '', title: 'GS' },
        { width: '', title: 'SG' },
    ]

    function Confronto({ item }) {
        const [project, setProject] = useState(item)
        const [step, setStep] = useState(item.gols_casa || item.gols_fora ? 1 : 0)
        function handleChange(e) {
            setProject({ ...project, [e.target.name]: e.target.value })
        }
        const placar = [
            { id: '0' },
            { id: 1 },
            { id: 2 },
            { id: 3 },
            { id: 4 },
            { id: 5 },
            { id: 6 },
            { id: 7 },
            { id: 8 },
            { id: 9 },
            { id: 10 },
        ]

        const aberto = (
            <div className="confronto">
                <div>
                    <div>
                        <span>{item.time_casa}</span>
                        <Select
                            options={placar}
                            handleOnChange={handleChange}
                            name='gols_casa'
                            value={project.gols_casa}
                        />
                    </div>
               
                    <label> VS </label>
               
                    <div>

                        <Select
                            options={placar}
                            handleOnChange={handleChange}
                            name='gols_fora'
                            value={project.gols_fora}
                        />
                        <span>{item.time_fora}</span>
                    </div>
                </div>
               
                <Button
                    value='Salvar'
                    color='#008C56'
                    click={()=> update(`/updatePlacar`,project, setRefresh)}
                />
            </div>
        )
        const encerrado = (
            <div className="confronto">
                <div>
                    <div>
                        <span>{item.time_casa}</span>
                        <div>{item.gols_casa}</div>
                    </div>
               
                    <label> VS </label>
               
                    <div>

                    <div>{item.gols_fora}</div>
                        <span>{item.time_fora}</span>
                    </div>
                </div>
              
                <Button
                    value='Alterar'
                    color='#0042A3'
                    click={()=> setStep(0)}
                />
            </div>
           
        )

        const steps = [
            aberto,
            encerrado
        ]

        return (
            steps[step]
        )

    }

    return (
        <div id='copinhaContainer'>
            <div id='att' onClick={() => setRefresh(Math.random())}>
            <HiOutlineRefresh/>
            </div>
            <div id='voltar' onClick={() => setStep(0)}>
            <MdOutlineKeyboardReturn/>
            </div>

            <Table>
                <Th array={titles} />
                {tabela.tabela.map((time, index) =>
                    <tr key={index}>
                        <td>
                            {time.time}
                        </td>

                        <td>
                            {parseInt(time.vitorias) * 3 + parseInt(time.empates)}
                        </td>

                        <td>
                            {time.feitos}
                        </td>

                        <td>
                            {time.sofridos}
                        </td>

                        <td>
                            {time.saldo_gols}
                        </td>
                    </tr>
                )}

            </Table>

            {tabela.jogos.map((jogo, index) =>

                <Confronto item={jogo} />
            )}
        </div>
    )
}