// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../img/wall2.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".loginForm{\r\n    background-color:rgba(49, 49, 49, 0.281) ;\r\n    padding: 1em;\r\n    display: flex;\r\n    align-items: center;\r\n    flex-direction: column;\r\n    border-radius: 5px;\r\n    box-shadow: rgba(5, 5, 5, 0.2) 0px 2px 8px 0;\r\n}\r\n\r\n.wall{\r\ndisplay: flex;\r\nalign-items: center;\r\njustify-content: center;\r\n width: 100vw;\r\n height: 100vh;\r\nbackground-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\r\nbackground-color: rgb(219, 189, 214);\r\nbackground-repeat: no-repeat;\r\nbackground-position: center;\r\nbackground-size:90%;\r\n}\r\n\r\n.wall label{\r\n    color: azure;\r\n}\r\n\r\n.logo2{\r\n    width: 100vw;\r\n}", "",{"version":3,"sources":["webpack://./src/components/a_pages/Login.css"],"names":[],"mappings":"AAAA;IACI,yCAAyC;IACzC,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB;IAClB,4CAA4C;AAChD;;AAEA;AACA,aAAa;AACb,mBAAmB;AACnB,uBAAuB;CACtB,YAAY;CACZ,aAAa;AACd,yDAA0C;AAC1C,oCAAoC;AACpC,4BAA4B;AAC5B,2BAA2B;AAC3B,mBAAmB;AACnB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".loginForm{\r\n    background-color:rgba(49, 49, 49, 0.281) ;\r\n    padding: 1em;\r\n    display: flex;\r\n    align-items: center;\r\n    flex-direction: column;\r\n    border-radius: 5px;\r\n    box-shadow: rgba(5, 5, 5, 0.2) 0px 2px 8px 0;\r\n}\r\n\r\n.wall{\r\ndisplay: flex;\r\nalign-items: center;\r\njustify-content: center;\r\n width: 100vw;\r\n height: 100vh;\r\nbackground-image: url(../../img/wall2.png);\r\nbackground-color: rgb(219, 189, 214);\r\nbackground-repeat: no-repeat;\r\nbackground-position: center;\r\nbackground-size:90%;\r\n}\r\n\r\n.wall label{\r\n    color: azure;\r\n}\r\n\r\n.logo2{\r\n    width: 100vw;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
