import React, { useState, useEffect, useMemo } from "react";
import { Context } from "../e_contexts/AuthContext";
import { Page } from "../../a_model/Container";
import { getTheme } from "../../a_model/Theme";
import { useContext } from "react";
import { InsumosForm } from "../b_forms/InsumosForm";

export default function Insumos() {
    
    const { params } = useContext(Context)
    useEffect(() => {
        console.log(params)
     }, [])
   
    const [currentFicha, setCurrentFicha] = useState(0)
    const navList = ['Cadastro']
    const steps = [
    <InsumosForm/>
       
    ]

    return (    
            <Page>
                
                <body style={{background: getTheme(localStorage.getItem('tema'), 1)}}>
                    {steps[currentFicha]}
                </body>
            </Page>
    )
}
