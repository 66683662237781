import React, { useEffect, useState } from "react";
import './Inicio.css'
import { get, insert, update } from "./fetch";
import { Table, Th } from "../a_model/Contents";
import { HiOutlineRefresh } from "react-icons/hi";
import { MdOutlineKeyboardReturn } from "react-icons/md";

export default function Selecionar({ setId_copinha, setStep }) {
    const [copinhas, setCopinhas] = useState([])

    const [refresh, setRefresh] = useState(false)
    useEffect(() => {
            get(`/getCopinhas`, setCopinhas)
    }, [refresh])

    const titles = [
        { width: '', title: 'Data' },
        { width: '', title: 'Código' },
      
    ]

    function selecionar(id){
        setStep(2)
        setId_copinha(id)
    }

    return (
        <div id='copinhaContainer'>
            <div id='att' onClick={() => setRefresh(Math.random())}>
            <HiOutlineRefresh/>
            </div>
            <div id='voltar' onClick={() => setStep(0)}>
            <MdOutlineKeyboardReturn/>
            </div>

            <Table>
                <Th array={titles} />
                {copinhas.map((copinha, index) =>
                    <tr key={index} onClick={()=> selecionar(copinha.id)}>
                        <td>
                            {copinha.data}
                        </td>

                        <td>
                            {copinha.id}
                        </td>
                    </tr>
                )}

            </Table>
        </div>
    )
}