import React, { useEffect, useMemo, useState } from "react"
import { InputCash, Button, InputSearch, InputSearch2, InputDate, Select, InputNumber, LabelText3 } from "../../a_model/Input"
import { get, insert } from '../f_aux/fetch'
import { Card } from "../../a_model/Container";
import moment from "moment";
import css from './forms.module.css'
import { produce } from 'immer'
import { getTheme } from "../../a_model/Theme";
import { BsTrash } from "react-icons/bs";

export function CadastroVenda({setDialog}) {

    const pagamento = [
        { id: '0', nome: 'Pix' },
        { id: 1, nome: 'Débito' },
        { id: 2, nome: 'Crédito' },
        { id: 3, nome: 'Dinheiro' },
        { id: 4, nome: 'Outro' },
    ]
    const venda_sub = { id_produto: '', valor: 0, qnt: 1 }
    const [project, setProject] = useState({ valor: 0, data: moment().format('YYYY-MM-DD'), pagamento: 100, id_cliente: '', itens: [venda_sub] })
    const [statusButton, setStatusButton] = useState('Cadastrar')
    const [clientes, setClientes] = useState([])
    const [produtos, setProdutos] = useState([])
    useEffect(() => {
        get(`/getClientes/1`, setClientes)
        get(`/getProdutos/1`, setProdutos)

    }, [])

    function adicionar() {
        setProject(produce(project, draft => {
            draft.itens.push(venda_sub)
        }))
    }

    function remover(index) {
        setProject(produce(project, draft => {
            draft.itens.splice(index, 1)
            draft.valor = 0
            for (let item of draft.itens) {
                draft.valor += parseFloat(item.valor) * item.qnt
            }
        }))
    }

    function handleChange(e) {
        setProject({ ...project, [e.target.name]: e.target.value })
    }

    function setCliente(value) {
        setProject({ ...project, ['id_cliente']: value.id, ['nome_cliente']: value.nome })
    }

    function setProduto(produto, index) {
        setProject(produce(project, draft => {
            draft.itens[index].id_produto = produto.id
            draft.itens[index].nome_produto = produto.nome
            draft.itens[index].valor = produto.valor
            if (draft.itens[draft.itens.length - 1].id_produto) {
                draft.itens.push(venda_sub)

            }
            draft.valor = 0
            for (let item of draft.itens) {
                draft.valor += parseFloat(item.valor) * item.qnt
            }

        }))
    }
    function subChange(e, index) {
        setProject(produce(project, draft => {
            draft.itens[index][e.target.name] = e.target.value

            draft.valor = 0
            for (let item of draft.itens) {
                draft.valor += parseFloat(item.valor) * item.qnt
            }

        }))

    }

    function insertVenda(project) {
        if (!project.id_cliente) {
            window.alert('Selecione o cliente')
        } else if (!project.itens[0].id_produto) {
            window.alert('Adicione pelo menos um produto')
        } else if (project.pagamento == 100) {
            window.alert('Selecione o método de pagamento')
        } else {
            setStatusButton('...')
            insert(`/insertVenda`, project, then)
            function then() {
                setDialog({ text: 'Venda Registrada!', type: 0, att: Math.random() })
                setStatusButton('Cadastrar')
                setProject({ valor: 0, data: moment().format('YYYY-MM-DD'), pagamento: 100, id_cliente: '', itens: [venda_sub] })
            }
        }
    }

    return (
        <Card
            height='90%'
            width='700px'
            header={
                <>
                    <div>
                        <div style={{ background: '', width: '600px', display: 'flex', height: '', alignItems: 'center', justifyContent: 'space-between' }}>
                            {project.id_cliente ?
                                <LabelText3
                                    onClick2={()=> setProject({...project,['id_cliente']:''})}
                                >
                                    {project.nome_cliente}
                                </LabelText3>
                                :
                                <ClientesBar
                                    setCliente={setCliente}
                                    clientes={clientes}
                                />
                            }
                            <div style={{ display: 'flex', alignItems: 'center', background: '' }}>

                                <LabelText3
                                    header='Total'
                                    row={true}
                                >

                                    {project.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                </LabelText3>
                            </div>

                        </div>
                        <div style={{ background: '', width: '600px', display: 'flex', height: '70px', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                Data da venda:
                                <InputDate
                                    title='Data da venda'
                                    value={project.data}
                                    handleOnChange={handleChange}
                                    name='data'
                                />
                            </div>
                            <Select
                                name='pagamento'
                                value={project.pagamento}
                                padrao='Forma de pagamento'
                                options={pagamento}
                                handleOnChange={handleChange}
                            />
                        </div>
                    </div>

                </>

            }
        >
            <div id={css.itensContainer}>

                {project.itens.map((item, index) =>
                    <div key={index} className={css.itemVenda} style={{ background: getTheme(localStorage.getItem('tema'), 1) }}>
                        {item.id_produto ?
                            <>
                                <LabelText3
                                    width='250px'
                                >
                                    {item.nome_produto}
                                </LabelText3>
                                <InputCash
                                    flex='column'
                                    name='valor'
                                    width='100px'
                                    value={item.valor}
                                    handleOnChange={(e) => subChange(e, index)}
                                />

                                <InputNumber
                                    placeholder='qnt'
                                    width='58px'
                                    name='qnt'
                                    value={item.qnt || ''}
                                    handleOnChange={(e) => subChange(e, index)}

                                />
                                <LabelText3
                                    width='100px'
                                >
                                    {(parseFloat(item.valor) * item.qnt).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                </LabelText3>
                            </>
                            :
                            <ProdutosBar
                                indexSub={index}
                                produtos={produtos}
                                setProduto={setProduto}
                            />
                        }


                        {(index > 0 || item.id_produto) && <button onClick={() => remover(index)}><BsTrash /></button>
                        }
                    </div>

                )}
                <Button
                    color='#11883e'
                    click={() => adicionar()}
                >
                    Adicionar
                </Button>
            </div>


            <footer style={{ display: 'flex', width: '400px', justifyContent: 'space-around' }}>

                <Button
                    color='#295b9c'
                    click={() => insertVenda(project)}
                    value={statusButton || '...'}
                    disabled={statusButton == 'Cadastrar' ? false : true}
                />
            </footer>

        </Card >

    )
}

function ClientesBar({ clientes, setCliente }) {

    const [search, setSearch] = useState('');
    const results = useMemo(() => {

        const lowerBusca = search.toLowerCase()
        return clientes.filter(user => user.nome ? (user.nome.toLowerCase().includes(lowerBusca) || user.telefone.includes(lowerBusca) || String(user.id).includes(lowerBusca)) : '');


    }, [search])
    return (
        <InputSearch
            placeholder='Pesquisar Cliente...'
            width='400px'
            search={search}
            setSearch={setSearch}>

            <ListItem
                search={search}
                setCliente={setCliente}
                results={results}
            />
        </InputSearch>
    );
}

function ListItem({ results, setCliente, search }) {
    function formatTel(telefone) {
        let retorno = String(telefone.substr(2, telefone.length - 1).split(/[()-]/))
        return `(${retorno.substr(0, 2)}) ${retorno.substr(2, retorno.length - 6)}-${retorno.substr(-4, 4)}`
    }
    return (
        search.length > 2 &&
        <>
            <ul >
                {results.map((result, index) => (

                    <li key={index} onClick={() => setCliente(result)}>
                        <span>{result.nome || '-'}</span>{result.telefone && <span>{formatTel(result.telefone)}</span>}
                    </li>

                ))}
            </ul>

        </>
    );
}

function ProdutosBar({ produtos, setProduto, indexSub }) {

    const [search, setSearch] = useState('');
    const results = useMemo(() => {

        const lowerBusca = search.toLowerCase()
        return produtos.filter(user => user.nome ? (user.nome.toLowerCase().includes(lowerBusca) || String(user.id).includes(lowerBusca)) : '');


    }, [search])
    return (
        <InputSearch2
            placeholder='Pesquisar Produto...'
            width='245px'
            search={search}
            setSearch={setSearch}>

            <ListItem2
                indexSub={indexSub}
                search={search}
                setProduto={setProduto}
                results={results}
            />
        </InputSearch2>
    );
}

function ListItem2({ results, setProduto, search, indexSub }) {

    return (
        search.length > 2 &&
        <>
            <ul >
                {results.map((result, index) => (

                    <li key={index} onClick={() => setProduto(result, indexSub)}>
                        <span>{result.nome || '-'}</span><span>{parseFloat(result.valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
                    </li>

                ))}
            </ul>

        </>
    );
}