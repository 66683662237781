import { useState, useContext } from 'react';
import React from 'react';
import { LoginPage } from '../../a_model/Container';
import { Context } from '../e_contexts/AuthContext';
import { get, insert } from '../f_aux/fetch';

export default function Login() {
    const [login, setLogin] = useState({ login: '', senha: '' })
    const { handleLogin } = useContext(Context)

    async function handleChange(e) {
        setLogin({ ...login, [e.target.name]: e.target.value })
    }

    function logar() {

        insert(`/logar`, login, then)
        function then(resp) {
            if (resp) {
                if (resp.senha) {
                    handleLogin(resp)
                } else {
                    window.alert('Senha incorreta')
                }
            } else {
                window.alert('Login incorreto')
            }
        }
    }
    return (

        <LoginPage
            logar={logar}
            login={login}
            handleChange={handleChange}
        />
    )

}
