import "./BForm.css"
import { InputText, TextArea } from '../../../a_model/Input'
import Radio from '../../../a_model/RadioA'
import { Checkbox } from "../../d_inputs/Input2"


const BForm = ({ data, updateField, handleChange }) => {
    const opc = ['Cooperativo', 'Indiferente', 'Resistente']
    const opd = ['Autopsíquica', 'Alopsíquica', 'Temporal', 'Espacial']
    const ope = ['Normal', 'Hipervigilância', 'Hipovigilância']
    const opf = ['Normal', 'Hipertenacidade', 'Hipotenacidade']
    const opg = ['Coerente', 'Incoerente', 'Desconexo', 'Conexo']
    const oph = ['Normal', 'Alucinação']
    const opi = ['Normal', 'Acelerado', 'Retardado', 'Fuga', 'Bloqueio', 'Prolixo', 'Descarrilamento']
    const opj = ['Normal', 'Disartrias', 'Afasias', 'Parafasia', 'Neologismo', 'Mussitação', 'Logorréia', 'Para-respostas']
    const opk = ['Distimia', 'Angústia', 'Ansiedade', 'Irritabilidade', 'Medo', 'Ambivalência afetiva', 'Pânico']
    const opl = ['Normal', 'Exaltado', 'Baixa de humor', 'Quebra súbita...']
    const opm = ['sim', 'parcialmente', 'não']

    return (
        <div className="reviewForm">
            <h1>Exame Psíquico:</h1>




            <div className="form-control score-container2">
                <div className="score1">

                    <InputText
                        placeholder="Normal"
                        title="Aparência:"
                        value={data.ba || ''}
                        name='ba'
                        handleOnChange={(e) => updateField("ba", e.target.value)}
                    />

                    <div style={{ margin: '10px 0' }}>
                        <InputText
                            placeholder="Normal"
                            title="Comportamento:"
                            value={data.bb || ''}
                            name='bb'
                            handleOnChange={(e) => updateField("bb", e.target.value)}
                        />
                    </div>

                    <div className="radios">
                        <label htmlFor='bd'>Orientação: </label>
                        <Checkbox
                            name="bd1"
                            updateField={updateField}
                            item={data.bd1}
                            values={opd[0]}
                        />
                        <Checkbox
                            name="bd2"
                            updateField={updateField}
                            item={data.bd2}
                            values={opd[1]}
                        />
                        <Checkbox
                            name="bd3"
                            updateField={updateField}
                            item={data.bd3}
                            values={opd[2]}
                        />
                        <Checkbox
                            name="bd4"
                            updateField={updateField}
                            item={data.bd4}
                            values={opd[3]}
                        />
                    </div>
                    <Radio
                        name="be"
                        title="Vigilância: "
                        updateField={updateField}
                        data={data.be}
                        values={ope}
                    />
                    <Radio
                        name="bf"
                        title="Tenacidade: "
                        updateField={updateField}
                        data={data.bf}
                        values={opf}
                    />
                    <Radio
                        name="bg"
                        title="Discurso: "
                        updateField={updateField}
                        data={data.bg}
                        values={opg}
                    />
                    <Radio
                        name="bh"
                        title="Sensopercepção: "
                        updateField={updateField}
                        data={data.bh}
                        values={oph}
                    />

                </div>
                <div className="score2">
                    <Radio
                        name="bl"
                        title="Humor: "
                        updateField={updateField}
                        data={data.bl}
                        values={opl}
                    />
                    <Radio
                        name="bc"
                        title="Atitude para com o entrevistador: "
                        updateField={updateField}
                        data={data.bc}
                        values={opc}
                    />
                    <Radio
                        name="bi"
                        title="Pensamento: "
                        updateField={updateField}
                        data={data.bi}
                        values={opi}
                    />
                    <Radio
                        name="bj"
                        title="Linguagem: "
                        updateField={updateField}
                        data={data.bj}
                        values={opj}
                    />
                    <div className="radios">
                        <label htmlFor='bk'>Afetividade: </label>
                        <Checkbox
                            name="bk1"
                            updateField={updateField}
                            item={data.bk1}
                            values={opk[0]}
                        />
                        <Checkbox
                            name="bk2"
                            updateField={updateField}
                            item={data.bk2}
                            values={opk[1]}
                        />
                        <Checkbox
                            name="bk3"
                            updateField={updateField}
                            item={data.bk3}
                            values={opk[2]}
                        />
                        <Checkbox
                            name="bk4"
                            updateField={updateField}
                            item={data.bk4}
                            values={opk[3]}
                        />
                        <Checkbox
                            name="bk5"
                            updateField={updateField}
                            item={data.bk5}
                            values={opk[4]}
                        />
                        <Checkbox
                            name="bk6"
                            updateField={updateField}
                            item={data.bk6}
                            values={opk[5]}
                        />
                        <Checkbox
                            name="bk7"
                            updateField={updateField}
                            item={data.bk7}
                            values={opk[6]}
                        />
                    </div>


                    <Radio
                        name="bm"
                        title="Consiência da doença atual: "
                        updateField={updateField}
                        data={data.bm}
                        values={opm}
                    />
                    <InputText
                        width='100%'
                        title="Observação: "
                        name="obs"
                        value={data.obs || ''}
                        handleOnChange={handleChange}
                    />


                </div>
            </div>

        </div>
    )
}

export default BForm