import React, { useEffect, useMemo, useState } from 'react';
import './Record.css';

import logo from '../../img/logop.png'
import { Page } from '../../a_model/Container';
import { Header } from '../../a_model/Header';
import { get } from '../../a_model/fetch';
import { Context } from '../e_contexts/AuthContext';
import Clients from '../b_forms/Clients';

export default function Clientes() {

    const [step, setStep] = useState(0)
    
    return (
        <Context.Provider value={{
            setStep,
        }}>
            <Page>
            <Clients />
            </Page>

        </Context.Provider >
    )

}