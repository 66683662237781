import css from './Home.module.css'
import { useEffect, useState, useContext } from 'react';
import { Context } from '../e_contexts/AuthContext';
import { getTheme } from '../../a_model/Theme'
import { HomeContainer } from '../../a_model/Container';
import { produce } from 'immer'
import { CiBoxes, CiUser, CiBoxList,CiDeliveryTruck, CiCalculator1, CiCircleList, CiSettings } from "react-icons/ci";
import Clientes from './Clientes';
import Lancamentos from './Lancamentos';
import Produtos from './Produtos';
import Insumos from './Insumos';

export default function Home() {

  const listModule = [
    
   [
    'Clientes',
    <Clientes abrir={abrir} />
   ],
   [
    'Lançamentos',
    <Lancamentos/>
   ],
   [
    'Produtos',
    <Produtos/>
   ],
   [
    'Insumos',
    <Insumos/>
   ]
  ]
  const [currentModule, setCurrentModule] = useState(0);
  const [tema, setTema] = useState(6)
  const [activeName, setActiveName] = useState([])
  const [activeModule, setActiveModule] = useState([])
  const { handleLogout, params, verif } = useContext(Context)
  useEffect(() => {
    if (localStorage.getItem('tema') != tema) {
      setTema(localStorage.getItem('tema'))
    }

  }, [])

  function abrir(index) {
    setActiveName(produce(activeName, draft => {
      draft.push(listModule[index][0])
    }))
    setActiveModule(produce(activeModule, draft => {
      draft.push(listModule[index][1])
      setCurrentModule(draft.length - 1)
    }))

  }
  function fechar(index) {
    if (currentModule === index) {
      setCurrentModule(0)

    } 
    setActiveName(produce(activeName, draft => {

      draft.splice(index, 1)
    }))
    setActiveModule(produce(activeModule, draft => {
      draft.splice(index, 1)


    }))

  }

  function CardHome() {
   
    return (
      <div id='cardHome'>
        <span> Usuário: </span>
        <label style={{ color: getTheme(tema, 0) }}>{params.nome}</label>
       </div>
    )
  }

  function HoverDropdown() {
    
    return (

      <ul className={css.dropdownMenu} >
         <li onClick={() => abrir(0)}>
          <CiUser color={getTheme(tema, 0)} />
          <span>
            Clientes
          </span>
        </li>
        <li onClick={() => abrir(1)}>
          <CiBoxList color={getTheme(tema, 0)} />
          <span>
            Lancamentos
          </span>
        </li>
        <li onClick={() => ''}>
          <CiCalculator1 color={getTheme(tema, 0)} />
          <span>
            Caixa
          </span>
        </li>
        <li onClick={() => abrir(2)}>
          <CiBoxes color={getTheme(tema, 0)} />
          <span>
            Produtos
          </span>
        </li>
        <li onClick={() => abrir(3)}>
          <CiDeliveryTruck color={getTheme(tema, 0)} />
          <span>
            Insumos
          </span>
        </li>
        <li onClick={() => ''}>
          
          <CiSettings color={getTheme(tema, 0)} />
          <span> Personalizar</span>
        </li>
      </ul>

    );
  }

  return (
    <Context.Provider value={{
      handleLogout, params
    }}>
      <div className={css.agenda} >

        <div className={css.dayMenu} style={{ background: '#fff' }} >
         
          <CardHome />
          <HoverDropdown />
        </div>

        <div className={css.dayPage} style={{ background: '#fff' }}>
          <HomeContainer
            currentModule={currentModule}
            setCurrentModule={setCurrentModule}
            activeName={activeName}
            fechar={fechar}
            sair={handleLogout}
          >
            {activeModule.length > 0 ?
              activeModule.map((module, index) =>
                <div
                  key={index}
                  style={{ display: index === currentModule ? 'block' : 'none' }}>
                  {module}
                </div>
              )

              : 
              <h1 id={css.welcome}>Seja Bem-vindo!</h1>
            }
          </HomeContainer>
        </div>

      </div>
    </Context.Provider>
  )
}
