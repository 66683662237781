import React, { useState } from 'react';
import { MdOutlineDelete } from 'react-icons/md'
import { BiEdit } from 'react-icons/bi'
import { Button, InputMasks, InputDate, InputText, TextArea, InputTime } from '../../a_model/Input'
import { Card } from '../../a_model/Container';
import { deletar, update } from '../../a_model/fetch';
import { toDate } from '../../a_model/functions';
import { Table, Th } from '../../a_model/Contents';

export default function ResumoCard({ sessions, getSessions }) {


    function InfoEdit({ session }) {


        const [edita, setEdita] = useState(0)

        const forms = [
            <InfoCard setEdita={setEdita} session={session} />,
            <EditCard setEdita={setEdita} session={session} getSessions={getSessions} />
        ]
        return (
            <div>
                {forms[edita]}
            </div>
        )
    }

    return (

        <div className="sessoes">
            {sessions.map(session => {

                return (
                    <InfoEdit
                        session={session}
                    />
                )
            })}


        </div>
    )
}


function InfoCard({ session, setEdita, getSessions }) {
    function deleteSession(id) {

        var resp = window.confirm("Confirma a exclusão deste registro?");
        if (resp) {

            deletar(`/delete4/${id}`, getSessions)

        }
    }
    return (
        <Card
            header={
                <>
                    <label>Sessão: <label className="value"> {session.sessao}</label></label>
                    <label>Data: <label className="value"> {toDate(session.data)}</label></label>
                    
                    <div

                        id={session.id}
                        onClick={() => setEdita(1)}

                    >
                        <BiEdit />
                    </div>
                    <div

                        id={session.id}
                        onClick={() => deleteSession(session.id)}

                    >
                        <MdOutlineDelete />
                    </div>

                </>
            }
        >
            <div className='session' key={session.id}>

                <label>Resumo: <span className="value"> {session.resumo}</span></label>
                
            </div>
        </Card>
    )
}

function EditCard({ session, getSessions, setEdita }) {
    const [project, setProject] = useState({...session, ['data']: session.data.substr(0,10)})

    function handleChange(e) {
        setProject({ ...project, [e.target.name]: e.target.value })

    }
 
    const titles2 = [
        { title: 'Sessão' },
        { title: 'Data' },

        { title: '*' },
        { title: '*' },
    ]

    return (
        <Card
            header={
                <>
                    <Table>
                        <Th array={titles2} />
                        <tr>
                            <td>
                                <InputText
                                    width='2.5em'
                                    value={project.sessao || ''}
                                    name='sessao'
                                    handleOnChange={handleChange}
                                />
                            </td>
                            <td>
                                <InputDate
                                    name="data"
                                    value={project.data}
                                    handleOnChange={handleChange}
                                />
                            </td>
                           
                            <td>
                                <Button
                                    color='#2c61c4'
                                    value='Salvar'
                                    click={() =>  update(`/update5/${session.id}`, project, getSessions)}
                                />
                            </td>
                            <td>
                                <Button
                                    color='#554'
                                    value='Cancelar'
                                    click={() => setEdita(0)}
                                />
                            </td>
                        </tr>
                    </Table>
                </>
            }>
            <div style={{ width: '90%' }}>
                <TextArea
                    
                    name='resumo'
                    value={project.resumo || ''}
                    handleOnChange={handleChange}
                    title="Resumo"
                />
               
            </div>
        </Card>
    )

}