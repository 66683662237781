import React, { useState, useEffect, useMemo } from 'react';
import "./Control.css"
import { MdOutlineDelete } from 'react-icons/md'
import { BiEdit } from 'react-icons/bi'
import { InputMasks, InputText, Button, InputDate, Select } from '../../a_model/Input';
import moment from 'moment'
import { SearchList, Table, Th } from '../../a_model/Contents'
import CreateUser from './CreateUser';
import EditUser from './EditUser';

export default function Clients() {
  const [records, setRecords] = useState([])
  const [step, setStep] = useState(1)
  const [iduser, setIduser] = useState(false)
  useEffect(() => {

    fetch(`${process.env.REACT_APP_BACKEND}/recordsList`, {
      method: "GET",
      headers: {
        'Content-type': 'application/json',
      },
    })
      .then((resp) => resp.json())
      .then((resp2) => {

        setRecords(resp2)


      })
      .catch(err => console.log(err))
  }, [step])


  const titles = [
    { title: 'nº' },
    { title: 'Nome' },
    { title: 'Último' },
    { title: 'Início' },
    { title: 'Total' },
    { title: 'Editar' },
  ]


  function setStep2(x) {
    setStep(x)
    setIduser(false)
  }
  function setToEdit(iduser){
    setStep(3)
    setIduser(iduser)
  }
  function Td({ index, record }) {
    //  console.log(record)
    const colorStatus = ['#881212', '#128822']
    const nameStatus = ['Inativo', 'Ativo']
    const [project, setProject] = useState(record)
    const [current, setCurrent] = useState(0)

    function salvar() {
      fetch(`${process.env.REACT_APP_BACKEND}/attRecord`, {
        method: "PUT",
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify(project),
      })
        .then((resp) => resp.json())
        .then((resp2) => {
          setCurrent(0)

        })
        .catch(err => console.log(err))

    }

    function handleChange(e) {

      setProject({ ...project, [e.target.name]: e.target.value })

    }

    function voltar() {

      setProject(record)
      setCurrent(0)
    }

    const label = (
      <tr id="" key={index} className='' >
        <td className=''>{project.num}</td>
        <td className='clickControl' style={{ fontWeight: 'bold', color: colorStatus[project.status], width: '350px' }} onClick={() => setToEdit(record.iduser)}>{record.name || '-'}</td>



        <td className=''>{project.ultimo.substr(0, 10).split('-').reverse().join('/')}</td>

        <td className=''>{project.inicio.substr(0, 10).split('-').reverse().join('/')}</td>
        <td className=''>{project.count}</td>


        <td className='dele'
          onClick={() => setCurrent(1)}
        ><BiEdit />
        </td>
      </tr>
    )

    const input = (
      <tr id="" key={index} className='' >
        <td className=''>
          <InputText
            width='40px'
            name='num'
            value={project.num}
            handleOnChange={handleChange}
          />
        </td>
        <td >{record.name || '-'}</td>

        <td className=''>
          <Button
            value={nameStatus[project.status]}
            color={colorStatus[project.status]}
            click={() => setProject({ ...project, ['status']: project.status == 0 ? 1 : 0 })}
          />
        </td>

        <td className=''>
          <InputDate
            width='20px'
            name='inicio'
            value={project.inicio.substr(0, 10)}
            handleOnChange={handleChange}
          />
        </td>
        <td className=''>{record.count}</td>


        <td className='dele' >

          <Button
            value='Salvar'
            color='#067be7'
            click={() => salvar()}
          />
          <Button
            value='Voltar'
            color='#7c7c7c'
            click={() => voltar(0)}
          />
        </td>
      </tr>
    )
    const tds = [label, input]
    return (
      tds[current]
    )
  }

  const [search, setSearch] = useState('')

  const stepTitles = ['Inativos', 'Ativos', 'Cadastrar']
  const results = useMemo(() => {
    const lowerBusca = search.toLowerCase()
    return records.filter(item => item.name ? (item.name.toLowerCase().includes(lowerBusca)) : '');
  }, [search, records])

  return (
    <div className="cPanel">

      <SearchList
        stepTitles={stepTitles}
        search={search}
        setSearch={setSearch}
        setStep={setStep2}
        step={step}
      >{
          step == 2 ?
            <CreateUser setStep={setStep} />
            : iduser ?
              <EditUser iduser={iduser} setIuser={setIduser} />
              :
              <Table height='80vh'>
                <Th array={titles} />
                {results.map((record, index) =>
                  record.status == step &&
                  <Td
                    index={index}
                    record={record}
                  />

                )}
              </Table>
        }
      </SearchList>


    </div>

  );
}

