import React, { useState, useEffect } from 'react';
import { Button, InputMasks, InputDate, InputText, TextArea, InputTime } from '../../a_model/Input'
import "./Evo.css"
import InfoCard from '../c_layouts/EvoCard';
import { Context } from '../e_contexts/AuthContext';
import { useContext } from 'react';
import moment from 'moment';
import { Card } from '../../a_model/Container'
import { deletar, get, insert, update } from '../../a_model/fetch';
import { Table3 } from '../../a_model/Contents';

function Evo() {
    const { idrecord } = useContext(Context)
    const [sessions, setSessions] = useState([])
    const [project, setProject] = useState({
        data: moment().format('YYYY-MM-DD'),
        hora: moment().format('HH:mm'),
        idrecord,
        session: ''
    })

    useEffect(() => {
        getSessions()

    }, [])

    function getSessions() {
        get(`/sessions/${idrecord}`, setSessions)
        setProject({
            data: moment().format('YYYY-MM-DD'),
            hora: moment().format('HH:mm'),
            idrecord,
            session: ''
        })

    }
    

    function handleChange(e) {
        setProject({ ...project, [e.target.name]: e.target.value })



    }
    const titles2 = [
        { title: 'Sessão', width: '60px' },
        { title: 'Data' },
        { title: 'Horário' },
        { title: '' },
    ]

    return (
        <div className="evoPanel">
            <div>
                <Card
                    width='100%'
                    header={
                        <Table3
                        header={titles2}
                        >
                            
                            <tr>
                                <td>
                                    <InputText
                                        width='50px'
                                        value={project.session || ''}
                                        name='session'
                                        handleOnChange={handleChange}
                                    />

                                </td>
                                <td>
                                    <InputDate

                                        name="data"
                                        value={project.data}
                                        handleOnChange={handleChange}
                                    />

                                </td>
                                <td>
                                    <InputTime
                                        name="hora"
                                        value={project.hora || ''}
                                        handleOnChange={handleChange}
                                    />
                                </td>
                                <td>
                                    <Button
                                        color='#447461'
                                        value='Registrar'
                                        click={() => insert(`/session`, project, getSessions)}
                                    />
                                </td>

                            </tr>
                        </Table3>
                    }
                >
                    <div style={{ width: '90%' }}>
                        <TextArea
                            name='demanda'
                            value={project.demanda || ''}
                            handleOnChange={handleChange}
                            title="Demanda trabalhada"
                        />
                        <TextArea

                            name='estado'
                            value={project.estado || ''}
                            handleOnChange={handleChange}
                            title="Estado do paciente"
                        />
                        <TextArea
                            name='evolucao'
                            value={project.evolucao || ''}
                            handleOnChange={handleChange}
                            title="Evolução do caso"
                        />
                        <TextArea
                            placeholder='Escuta clínica'
                            name='proc'
                            value={project.proc || ''}
                            handleOnChange={handleChange}
                            title="Procedimentos adotados"
                        />
                    </div>
                </Card>
            </div>
            <div>
                <InfoCard
                    sessions={sessions}
                    getSessions={getSessions}

                />
            </div>

        </div>
    );
}

export default Evo;