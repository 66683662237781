import React, { useContext, useState, useEffect, useMemo } from "react"
import { InputText, InputSearch2, Button, InputFloat, LabelText3, InputCash } from "../../a_model/Input"
import { get, insert } from '../f_aux/fetch'
import { Context } from "../e_contexts/AuthContext";
import { Dialog } from "../c_layouts/Dialog";
import { Card, RowForm } from "../../a_model/Container";
import { Table, Th } from '../../a_model/Contents'
import { getTheme } from "../../a_model/Theme";
import css from './forms.module.css'
import { produce } from 'immer'
import { IoIosCloseCircleOutline } from "react-icons/io";


export function ProdutosForm() {
    const { params } = useContext(Context)
    const [dialog, setDialog] = useState({ text: false, type: 0, att: Math.random() })
   
    return (

        <RowForm>
            <Dialog value={dialog} />
            <Produtos dialog={dialog} />
            <CadastroProduto setDialog={setDialog} />

        </RowForm>

    )
}

function Produtos({ dialog }) {
    const [list, setList] = useState([])

    useEffect(() => {
        get(`/getProdutos/1`, setList)

    }, [dialog])
    
    const titles = [
        { title: 'Nome' },
        { title: 'Custo' },
        { title: 'Valor' },
    ]
    return (
        <div>
            <h2>
                Lista de Produtos
            </h2>
            <Table>
                <Th array={titles} />

                {
                    list.map((item) =>
                        <tr>
                            <td>
                                {item.nome}
                            </td>
                          
                            <td>
                                {parseFloat(item.custo).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                            </td>
                          
                            <td>
                                {parseFloat(item.valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                            </td>
                        </tr>

                    )}

            </Table>


        </div>
    )

}

function CadastroProduto({ setDialog }) {
    const produtos_sub = { id_produto: '', qnt: 1, id_insumo: '', custo: 0 }


    const [project, setProject] = useState({ nome: '', custo: 0, valor: '', itens: [produtos_sub] })
    const [statusButton, setStatusButton] = useState('Cadastrar')



    function handleChange(e) {
        setProject({ ...project, [e.target.name]: e.target.value })
    }

    function setInsumo(item, index) {
        setProject(produce(project, draft => {
            draft.itens[index].id_insumo = item.id
            draft.itens[index].nome_insumo = item.nome
            draft.itens[index].custo = item.total
            draft.custo = 0
            for (let item of draft.itens) {
                draft.custo += parseFloat(item.custo) * item.qnt
            }

        }))
    }
    function adicionar() {
        setProject(produce(project, draft => {
            draft.itens.push(produtos_sub)

        }))
    }
    function remover(index) {
        setProject(produce(project, draft => {
            draft.itens.splice(index, 1)
            draft.custo = 0
            for (let item of draft.itens) {
                draft.custo += parseFloat(item.custo) * item.qnt
            }
        }))
    }
    function subChange(e, index) {
        setProject(produce(project, draft => {
            draft.itens[index][e.target.name] = e.target.value
            draft.custo = 0
            if (e.target.name == 'qnt') {
                for (let item of draft.itens) {
                    draft.custo += parseFloat(item.custo) * item.qnt
                }
            }
        }))

    }
    function insertProduto() {
        if (project.nome.length === 0){
            window.alert('Nome vazio')
        }else if(!project.valor){ 
            window.alert('Valor vazio')
        } else {
                     
            setStatusButton('...')
            insert(`/insertProduto`, project, then)
            function then() {
                setDialog({ text: 'Produto Cadastrado!', type: 0, att: Math.random() })
                setStatusButton('Cadastrar')
                setProject({ nome: '', custo: 0, valor: '', itens: [produtos_sub] })
            }
        }
    }

    return (

        <div height='500px'>

            <Card
                height='87vh'
                width='600px'
                header={
                    <>
                        <div style={{ alignItems: 'center', width: '510px' }}>
                         <h2>Cadastrar Produto</h2>
                         <InputText
                                    title='Nome'
                                    name='nome'
                                    
                                    width='300px'
                                    value={project.nome}
                                    handleOnChange={handleChange}
                                />
                            <div style={{ marginTop:'10px', width: '500px', display: 'flex' }}>
                                
                                <div style={{ width:'300px',marginRight: '10px' }}>
                                
                                <LabelText3
                                    header='Custo'
                                    row={true}
                                >
                                    {project.custo.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                </LabelText3>
                            </div>
                                     <InputCash
                                        width='120px'
                                        title='Valor'
                                        name='valor'
                                        value={project.valor}
                                        handleOnChange={handleChange}
                                    />
                            </div>
                        </div>


                    </>

                }
            >
                <div id={css.headerItens}>
                    <span style={{ marginLeft: '50px' }}>Insumo</span><span style={{ marginLeft: '115px' }}>Custo</span><span style={{ marginLeft: '40px' }}>Qnt</span><span style={{ marginLeft: '45px' }}>Total</span>
                </div>
                <div id={css.itensContainer}>

                    {project.itens.map((item, index) =>
                        <div key={index} className={css.itemVenda} style={{ background: getTheme(localStorage.getItem('tema'), 1) }}>
                            {item.id_insumo ?
                                <>
                                    <LabelText3
                                        width='260px'
                                    >
                                        {item.nome_insumo}
                                    </LabelText3>
                                    <LabelText3
                                    width='100px'
                                    >
                                        {parseFloat(item.custo).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                    </LabelText3>
                                    <InputFloat
                                        placeholder='qnt'
                                        width='53px'
                                        name='qnt'
                                        value={item.qnt}
                                        handleOnChange={(e) => subChange(e, index, 'peso')}

                                    />
                                    <LabelText3
                                     width='100px'
                                    >
                                        {(parseFloat(item.custo) * item.qnt).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                    </LabelText3>

                                </>
                                :
                                <InsumosBar
                                    indexSub={index}

                                    setInsumo={setInsumo}
                                />
                            }



                            <button onClick={() => remover(index)}> {index > 0 &&<IoIosCloseCircleOutline/>}</button>

                        </div>

                    )}
                    <Button
                        color='#11883e'
                        click={() => adicionar()}
                    >
                        Adicionar
                    </Button>
                </div>


                <footer style={{ display: 'flex', width: '400px', justifyContent: 'space-around' }}>

                    <Button
                        color='#295b9c'
                        click={() => insertProduto()}
                        value={statusButton || '...'}
                        disabled={statusButton == 'Cadastrar' ? false : true}
                    />
                    <div style={{ position: 'absolute', right: '-60px', bottom: '0' }}>

                    </div>
                </footer>

            </Card >
        </div>

    )
}

function InsumosBar({ setInsumo, indexSub }) {
    const [insumos, setInsumos] = useState([])
    const [search, setSearch] = useState('');
    useEffect(() => {
        get(`/getInsumos/1`, setInsumos)
    }, [])
    const results = useMemo(() => {
        const normalizeString = (str) => {
            return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
        }

        const lowerBusca = normalizeString(search);
        return insumos.filter(user => {
            if (user.nome) {
                const nomeNormalizado = normalizeString(user.nome);
                return nomeNormalizado.includes(lowerBusca) || String(user.id).includes(lowerBusca);
            }
            return false;
        });
    }, [search]);
    return (
        <InputSearch2
            placeholder='Pesquisar insumo...'
            width='300px'
            search={search}
            setSearch={setSearch}>

            <ListItem2
                indexSub={indexSub}
                search={search}
                setInsumo={setInsumo}
                results={results}
            />
        </InputSearch2>
    );
}

function ListItem2({ results, setInsumo, search, indexSub }) {

    return (
        search.length > 2 &&
        <>
            <ul >
                {results.map((result, index) => (

                    <li key={index} onClick={() => setInsumo(result, indexSub)}>
                        <span>{result.nome || '-'}</span><span>{parseFloat(result.total).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
                    </li>

                ))}
            </ul>

        </>
    );
}