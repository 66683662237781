import css from './Home.module.css'
import { useEffect, useState, useContext } from 'react';
import { Context } from '../e_contexts/AuthContext';
import { getTheme } from '../../a_model/Theme'
import { HomeContainer } from '../../a_model/Container';
import { produce } from 'immer'
import { CiViewList, CiUser, CiBoxList, CiDeliveryTruck, CiCalculator1, CiCircleList, CiWavePulse1  } from "react-icons/ci";
import Clientes from './Clientes';
import Record from './Record';
import { Dialog } from '../../a_model/Dialog';
import Lancamentos from './Lancamentos';
import Dashboard from './Dashboard';

export default function Home() {
  const [dialog, setDialog] = useState({ type: 0, text: '' })
  const listModule = [
    
    [
      'Clientes',
      <Clientes abrir={abrir} />
    ],
    [
      'Prontuários',
      <Record abrir={abrir} />
    ],
    [
      'Lançamentos',
      <Lancamentos abrir={abrir} />
    ],
    [
      'Dashboard',
      <Dashboard abrir={abrir} />
    ],
  ]
  const [currentModule, setCurrentModule] = useState(0);
  const [tema, setTema] = useState(6)
  const [activeName, setActiveName] = useState([])
  const [activeModule, setActiveModule] = useState([])
  const { handleLogout, params, verif } = useContext(Context)
  useEffect(() => {
    if (localStorage.getItem('tema') != tema) {
      setTema(localStorage.getItem('tema'))
    }

  }, [])

  function abrir(index) {
    setActiveName(produce(activeName, draft => {
      draft.push(listModule[index][0])
    }))
    setActiveModule(produce(activeModule, draft => {
      draft.push(listModule[index][1])
      setCurrentModule(draft.length - 1)
    }))

  }
  function fechar(index) {
    if (currentModule === index) {
      setCurrentModule(0)

    }
    setActiveName(produce(activeName, draft => {

      draft.splice(index, 1)
    }))
    setActiveModule(produce(activeModule, draft => {
      draft.splice(index, 1)


    }))

  }

  function CardHome() {

    return (
      <div id={css.cardHome}>
        <span> Usuário: </span>
        <label style={{ color: getTheme(tema, 0) }}> Layara</label>
      </div>
    )
  }

  function HoverDropdown() {

    return (

      <ul className={css.dropdownMenu} >
        <li onClick={() => abrir(0)}>
          <CiUser color={getTheme(tema, 0)} />
          <span>
            Clientes
          </span>
        </li>
        <li onClick={() => abrir(1)}>
          <CiViewList color={getTheme(tema, 0)} />
          <span>
            Prontuários
          </span>
        </li>
        <li onClick={() => abrir(2)}>
          <CiCalculator1 color={getTheme(tema, 0)} />
          <span>
            Lançamentos
          </span>
        </li>
        <li onClick={() => abrir(3)}>
          <CiWavePulse1  color={getTheme(tema, 0)} />
          <span> Dashboard</span>
        </li>
      </ul>

    );
  }

  return (
    <Context.Provider value={{
      handleLogout, params
    }}>
      <Dialog value={dialog} />
      <div className={css.agenda} >

        <div className={css.dayMenu} style={{ background: '#fff' }} >

          <CardHome />
          <HoverDropdown />
        </div>

        <div className={css.dayPage} style={{ background: '#fff' }}>
          <HomeContainer
            currentModule={currentModule}
            setCurrentModule={setCurrentModule}
            activeName={activeName}
            fechar={fechar}
            sair={handleLogout}
          >
            {activeModule.length > 0 ?
              activeModule.map((module, index) =>
                <div
                  key={index}
                  style={{ display: index === currentModule ? 'block' : 'none' }}>
                  {module}
                </div>
              )

              :
              <div style={{textAlign:'center', margin:'10px'}}>Seja Bem vinda! &#128522;</div>
            }
          </HomeContainer>
        </div>

      </div>
    </Context.Provider>
  )
}
