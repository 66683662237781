import React, { useState, useEffect, useMemo } from "react";
import { Page } from "../../a_model/Container";
import { getTheme } from "../../a_model/Theme";
import { CadastroVenda } from "../b_forms/CadastroVenda";
import { Dialog } from "../c_layouts/Dialog";
import { LancamentosForm } from "../b_forms/LancamentosForm";
export default function Lancamentos() {
    const [dialog, setDialog] = useState({ text: false, type: 0, att: Math.random() })
    
  useEffect(() => {

     }, [])
    

    return (    
            <Page>
                <Dialog value={dialog}/>
                <body style={{background: getTheme(localStorage.getItem('tema'), 1)}}>
                <LancamentosForm dialog={dialog}/>
                <CadastroVenda setDialog={setDialog}/>
                </body>
            </Page>
    )
}

