import React, { useState, useEffect } from 'react';
import "./Control.css"
import { MdOutlineDelete } from 'react-icons/md'
import { BsCheck2Square } from 'react-icons/bs'
import { BiEdit } from 'react-icons/bi'
import { InputTime, InputText, Button, InputDate, InputFloat } from '../../a_model/Input';
import { Context } from '../e_contexts/AuthContext';
import { useContext } from 'react';
import moment from 'moment';
import { deletar, get, insert, update } from '../../a_model/fetch';
import { Table, Table3, Th } from '../../a_model/Contents';
import { Card } from '../../a_model/Container';
import { toCash, toDate } from '../../a_model/functions'
function Control() {
  const { iduser, idrecord } = useContext(Context)
  const [sessions, setSessions] = useState([])
  const [project, setProject] = useState({
    data: moment().format('YYYY-MM-DD'),
    hora: '',
    valor: '',
    confirm: 0,
    iduser,
    sessao: 0
  })
  useEffect(() => {
    getSessions()
  }, [])
  useEffect(() => {
    setProject({
      data: moment().format('YYYY-MM-DD'),
      hora: moment().format('HH:mm'),
      valor: 0,
      confirm: 0,
      iduser,
      sessao: 0
    })
  }, [sessions])

  function getSessions() {
    get(`/control/${iduser}`, setSessions)
  }


  function deleteSession(id) {
    var resp = window.confirm("Confirma a exclusão deste registro?");
    if (resp) {
      deletar(`/delete3/${id}`, getSessions)
    }
  }

  function editConfirm(id) {

    var resp = window.confirm("Confirma pagamento da sessão?");
    if (resp) {

      update(`/confirm/${id}`, {}, getSessions)

    }
  }

  function handleChange(e) {
    setProject({ ...project, [e.target.name]: e.target.value })
  }

  function confirm(id) {
    editConfirm(id)
  }

  const titles = [
    { title: 'Sessão' },
    { title: 'Data' },
    { title: 'Horário' },
    { title: 'Valor' },
    { title: 'Pagamento' },
    { title: 'Editar' },
    { title: 'Excluir' },
  ]
  const titles2 = [
    { title: 'Sessão', width:'60px' },
    { title: 'Data' },
    { title: 'Horário' },
    { title: 'Valor' },
    { title: '' },
  ]

  function Td({ session }) {
    const [project, setProject] = useState(session)
    const [current, setCurrent] = useState(0)

    function handleChange(e) {

      setProject({ ...project, [e.target.name]: e.target.value })

    }

    function voltar() {
getSessions()
      setProject(session)
      setCurrent(0)
    }

    const label = (
      <tr id="idsession" key={project.id} >
        <td id={project.id} >{project.sessao || '-'}</td>
        <td >{toDate(project.data)}</td>
        <td >{(project.hora ? project.hora.substr(0, 5) : '-')}</td>
        <td >{toCash(project.valor)}</td>
        <td className='tdConfirm'>
          {project.confirm == '0' ?
            <Button
              color='#213e6d'
              value='Confirmar'
              className='btnconfirm'
              click={() => confirm(session.id)}
            />

            : <BsCheck2Square />}</td>
        <td className='dele' onClick={() => setCurrent(1)}>
          <BiEdit style={{ fill: '#2854b3be' }} />
        </td>
        <td className='dele' onClick={() => deleteSession(session.id)}>
          <MdOutlineDelete style={{ fill: '#c73f3fbe' }} />
        </td>
      </tr>
    )

    const input = (
      <tr id="idsession" key={session.id} >
        <td id={session.id} >
          <InputText
            width='2.5em'

            value={project.sessao || ''}
            name='sessao'
            handleOnChange={handleChange}
          />
        </td>
        <td >
          <InputDate

            name="data"
            value={project.data.substr(0, 10)}
            handleOnChange={handleChange}
          />
        </td>
        <td >

          <InputTime
            name="hora"
            value={project.hora || ''}
            handleOnChange={handleChange}

          />
        </td>
        <td >
          <InputFloat
            width='5em'
            name="valor"
            value={project.valor || ''}
            handleOnChange={handleChange}

          />
        </td>
        <td className='tdConfirm'>
          <Button
            color='#213e6d'
            value='Salvar'
            className='btnconfirm'
            click={() => update(`/update3`, project, voltar)}
          />
        </td>
        <td className='tdConfirm' >
          <Button
            color='#444'
            id={session.id}
            className='btnconfirm'
            click={() => voltar()}
            value='Voltar'
          />
        </td>
        <td className='dele'>
          <Button
            color='#b4b4b400'
            id={session.id}
            click={() => deleteSession(session.id)}
            value={<MdOutlineDelete style={{ fill: '#c73f3fbe' }} />}
          />
        </td>
      </tr>
    )
    const tds = [label, input]
    return (
      tds[current]

    )
  }
  return (
    <Card
      width='700px'
      height='97%'
      header={
        <>
          <Table3
            header={titles2}
          >
            <tr>
              <td>
                <InputText
                  width='50px'
                  value={project.sessao || ''}
                  name='sessao'
                  handleOnChange={handleChange}

                />
              </td>
              <td>
                <InputDate

                  name="data"
                  value={project.data}
                  handleOnChange={handleChange}
                />
              </td>
              <td>
                <InputTime
                  mask='0'

                  name="hora"
                  value={project.hora || ''}
                  handleOnChange={handleChange}

                />
              </td>
              <td>

                <InputFloat
                  width='5em'
                  name="valor"
                  value={project.valor || ''}
                  handleOnChange={handleChange}
                  required
                />
              </td>
              <td>
                <Button
                  className='btnControl'
                  color="#447461"
                  value='Registrar'
                  click={() => insert(`/control`, project, getSessions)}
                />
              </td>
            </tr>
          </Table3>
        </>
      }
    >



      <Table>
        <Th array={titles} />
        {sessions.map(session => {

          return (
            <Td
              session={session}
            />
          )
        })}
      </Table>
    </Card>
  );
}

export default Control;