import './Input.module.scss'
import { GiCheckMark } from 'react-icons/gi'
import InputMask from 'react-input-mask'
import { useState } from 'react'
import { BiEdit, BiCopy } from 'react-icons/bi'
import { MdOutlineClose } from "react-icons/md";
import { FcCalendar } from "react-icons/fc";
import moment from 'moment'
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { IoIosSearch } from "react-icons/io";

const masks = ["99h99", "999.999.999-99", "99 (99) 99999-9999"]

export function InputText({ width, flex, title, name, placeholder, handleOnChange, value }) {
    return (
        <div className='inputContainer' style={flex ? { flexDirection: flex } : { alignItems: 'center' }}>
            <label htmlFor={name}>{title}</label>
            <input
                key={name}
                style={{ width: width }}
                type='text'
                className='input'
                name={name}
                id={name}
                placeholder={placeholder}
                onChange={handleOnChange}
                value={value}
                onBlur={() => {}}
            />
        </div>
    )
}
export function InputCash({ width, flex, title, name, placeholder, handleOnChange, value }) {
    return (
        <div className={flex ? 'inputContainer' : 'inputContainer2'} style={flex ? { flexDirection: flex } : { alignItems: 'center' }}>
            <label htmlFor={name}>{title}</label>
            <span >R$</span>
            <input
                style={{ paddingLeft:'28px', width: width}}
                type='text'
                className='input'
                name={name}
                id={name}
                placeholder={placeholder}
                onInput={(e) => {
                    // Remover caracteres não numéricos (exceto ponto e vírgula para permitir decimais)
                    e.target.value = e.target.value.replace(/[^0-9.,]/g, '');

                    // Substituir vírgula por ponto para interpretação correta do número
                    e.target.value = e.target.value.replace(/,/g, '.');

                    // Adicionar sua lógica de manipulação de estado aqui, se necessário
                    handleOnChange(e);
                }}
                value={value}
            />
           
        </div>
    )
}
export function InputDate({ name, handleOnChange, value }) {
    return (
        <input
            className='input'
            style={{ width: '10em' }}
            type='date'
            name={name}
            key={name}
            onChange={handleOnChange}
            value={value} />
    )
}

export function Select({ value, padrao, style, name, options, handleOnChange, disabled }) {

    return (

        <select
            disabled={disabled}
            className='input'
            style={style}
            name={name}
            id='select'
            onChange={handleOnChange}
        >
            {padrao && <option value={100} >{padrao}</option>}
            {options.map((option) => (
                option.id && <option selected={value == option.id ? true : false} value={option.id} name={option.nome} key={option.id}>{(option.nome ? option.nome : option.id)}</option>
            ))}
        </select>
    )
}

export function InputMasks({ flex, placeholder, title, name, handleOnChange, value, mask }) {
    return (
        <div className='inputContainer' style={flex ? { flexDirection: flex } : { alignItems: 'center' }}>
            <label htmlFor={name}>{title}</label>
            <InputMask
                className='input'
                style={{ width: mask === '0' ? '6em' : '15em', flexDirection: flex }}
                placeholder={placeholder}
                mask={masks[mask]}
                name={name}
                id={name}
                onChange={handleOnChange}
                value={value}

            />
        </div>
    )
}

export function InputNumber({ width, flex, title, name, placeholder, handleOnChange, value }) {
    return (
        <div className='inputContainer' style={flex ? { flexDirection: flex } : { alignItems: 'center' }}>
            <label htmlFor={name}>{title}</label>
            <input
                style={{ width: width }}
                type='number'
                className='input'
                min='0'
                step='1'
                name={name}
                id={name}
                placeholder={placeholder}
                onInput={(e) => {
                    // Remover caracteres não numéricos (exceto ponto para permitir decimais)
                    e.target.value = e.target.value.replace(/[^0-9.,]/g, '');

                    // Adicionar sua lógica de manipulação de estado aqui, se necessário
                    handleOnChange(e);
                }}
                value={value}
            />
        </div>
    )
}

export function InputFloat({ width, flex, title, name, placeholder, handleOnChange, value }) {
    return (
        <div className='inputContainer' style={flex ? { flexDirection: flex } : { alignItems: 'center' }}>
            <label htmlFor={name}>{title}</label>
            <input
                style={{ width: width }}
                type='text' // Use 'text' para permitir pontos e vírgulas
                className='input'
                name={name}
                id={name}
                placeholder={placeholder}
                onInput={(e) => {
                    // Remover caracteres não numéricos (exceto ponto e vírgula para permitir decimais)
                    e.target.value = e.target.value.replace(/[^0-9.,]/g, '');

                    // Substituir vírgula por ponto para interpretação correta do número
                    e.target.value = e.target.value.replace(/,/g, '.');

                    // Adicionar sua lógica de manipulação de estado aqui, se necessário
                    handleOnChange(e);
                }}
                value={value}
            />
        </div>
    );
}


export function InputTime({ disabled, width, flex, title, name, placeholder, handleOnChange, value }) {
    return (
        <div className='inputContainer' style={flex ? { flexDirection: flex } : { alignItems: 'center' }}>
            <label htmlFor={name}>{title}</label>
            <input
                style={{ width: width, padding: '7px' }}
                type='time'
                className='input'
                name={name}
                id={name}
                placeholder={placeholder}
                onChange={handleOnChange}
                value={value}
                disabled={disabled} />
        </div>
    )
}

export function InputPass({ width, flex, title, name, placeholder, handleOnChange, value }) {
    return (
        <div className='inputContainer' style={flex ? { flexDirection: flex } : { alignItems: 'center' }}>
            <label htmlFor={name}>{title}</label>
            <input
                style={{ width: width }}
                type='password'
                className='input'
                name={name}
                id={name}
                placeholder={placeholder}
                onChange={handleOnChange}
                value={value}
            />
        </div>
    )
}

export function InputDate2({ name, handleOnChange, value, classe, buttons }) {

    return (
        <div className='inputDate'>

            {buttons && <span  name={name} value={moment(value).subtract(1, "days").format('YYYY-MM-DD')}><MdOutlineKeyboardArrowLeft /> Anterior</span>}
            <div id={classe} >
                <input

                    style={{ width: '10em' }}
                    type='date'
                    name={name}
                    id={name}
                    onChange={handleOnChange}
                    value={value} />
                <span style={{color: '#fff'}}>{moment(value).locale("pt-br").format('ddd, DD/MM/YY')}</span>
                <div><FcCalendar /></div>
            </div>
            {buttons && <span>Próximo <MdOutlineKeyboardArrowRight /></span>}
        </div>

    )
}

export function Checkbox({ padrao, label, event }) {
    const [check, setCheck] = useState(padrao || 0)

    function mudar() {
        event(check === 0 ? 1 : 0)
        setCheck(check === 0 ? 1 : 0)
    }



    return (
        <div className='checkBox'>
            <label>{label}</label>
            <div onClick={() => mudar()} >
                {check === 1 ? <GiCheckMark /> : ''}
            </div>
        </div>
    )
}

export const Radio = ({ handleOnChange, title, name, data, values }) => {
    return (
        <div className='radios'>
            <label htmlFor={name}>{title}</label>
            {values.map(i => {
                return (
                    <label className="radioContainer">
                        <input
                            key={i}
                            type="radio"
                            values={i}
                            value={i}
                            name={name}
                            required
                            checked={data === i}
                            onChange={handleOnChange}
                        />
                        <GiCheckMark />
                        <p>{i}</p>
                    </label>
                )
            })}
        </div>

    )
}

export function Button(props) {
    return (
        <div className='botao2'>

            <button
                style={{ opacity:props.disabled?0.7:1,backgroundColor: `${props.color}`, width: props.width ? props.width : '' }}
                type='button'
                className={props.className}
                onClick={props.click}
                disabled={props.disabled}
            >{props.children}{props.value}
            </button>
        </div>
    )
}
export function Button2(props) {
    return (
        <div className='button2'>
            <button
                type='button'
                onClick={props.onClick}
            >{props.children}{props.value}
            </button>
        </div>
    )
}



export function InputDateC({ flex, title, name, placeholder, handleOnChange, value, classe }) {
    return (
        <div id={classe} className='inputContainer' style={flex ? { flexDirection: flex } : { alignItems: 'center' }}>
            <label htmlFor={name}>{title}</label>
            <input
                style={{ width: '10em' }}
                type='date'
                name={name}
                className='input'
                id={name}
                placeholder={placeholder}
                onChange={handleOnChange}
                value={value} />
        </div>
    )
}

export function SelectC({ value, padrao, width, flex, text, name, options, handleOnChange, classe, disabled }) {

    return (
        <div id={classe} className={'inputContainer2'} style={flex ? { flexDirection: flex } : { alignItems: 'center' }}>
            <label htmlFor={name}>{text}</label>
            <select
                disabled={disabled}
                className='input'
                style={{ width: width }}
                name={name}
                id={name}
                onChange={handleOnChange}
                value={value || ''}
            >
                {padrao && <option value={100} >{padrao}</option>}
                {options.map((option) => (
                    option.id && <option selected={value == option.id ? true : false} value={option.id} name={option.nome} key={option.id}>{(option.nome ? option.nome : option.id)}</option>
                ))}
            </select>
        </div>
    )
}



export function Select2({ value, padrao, width, name, options, handleOnChange, disabled }) {

    return (
        <div id='inputSelect'>
            <select
                disabled={disabled}
                style={{ width: width }}
                name={name}
                id={name}
                onChange={handleOnChange}
            >
                {padrao && <option value={100} >{padrao}</option>}
                {options.map((option) => (
                    option.id && <option selected={value == option.id ? true : false} value={option.id} name={option.nome} key={option.id}>{(option.nome ? option.nome : option.id)}</option>
                ))}
            </select>
        </div>
    )
}

export function TextArea({ width, height, flex, title, name, placeholder, handleOnChange, value }) {
    return (
        <div className='taContainer' style={flex ? { flexDirection: flex } : { flexDirection: 'column' }}>
            <label>{title}</label><br />
            <textarea
                style={{ width: width, minHeight: height }}
                placeholder={placeholder}
                name={name}
                id={name}
                value={value}
                onChange={handleOnChange}
            />
        </div>
    )
}

export function LabelText(props) {

    const svg = [<BiCopy color={props.color} />, <BiEdit color={props.color} />]
    return (
        <div className='labelContainer' style={props.flex ? { flexDirection: props.flex } : { alignItems: 'end' }}>
            <label style={{ color: `${props.color}` }}>{props.header}</label>

            <span style={{ color: `${props.color}` }}>{props.children}</span>
            {props.onClick &&
                <div onClick={props.onClick} > {svg[props.svg]} </div>}
        </div>


    )

}

export function LabelText2(props) {
    return (
        <div className='labelContainer' id='labelContainer2' style={props.flex ? { flexDirection: props.flex } : { alignItems: 'end' }}>
            <label >{props.header}</label>

            <span>{props.children}</span>
            {props.onClick &&
                <div onClick={props.onClick} > <BiEdit /> </div>}
        </div>


    )

}
export function LabelText3(props) {
    return (
        <div  style={ props.row ? { alignItems: 'center' } : { flexDirection: 'column' } } id='labelText3'>
            <label >{props.header}</label>

            <div style={{width: props.width}}>
                <span >{props.children}</span>
                <div>
                {props.onClick &&<button onClick={props.onClick}>Copiar</button>}
                    {props.onClick2 &&<button onClick={props.onClick2}>Alterar</button>}
                </div>
            </div>
        </div>


    )

}
export function LabelText4(props) {
    return (
        <div   id='labelText4'>
            <label >{props.header}</label>

            <div style={{width: props.width}}>
                <span >{props.children}</span>
                <div>
                {props.onClick &&<button onClick={props.onClick}>Copiar</button>}
                    {props.onClick2 &&<button onClick={props.onClick2}>Alterar</button>}
                </div>
            </div>
        </div>


    )

}
export function ConfirmButton(props) {
    return (

        <button
            style={{ border: `1px solid ${props.color}`, color: props.color }}
            className='confirmButton'
            type='button'
            onClick={props.onClick}
        >
            {props.value}
        </button>

    )

}
export function CloseButton({ onClick }) {
    return (

        <button
            id='closeButton'
            type='button'
            onClick={onClick}
        >
            <MdOutlineClose />
        </button>

    )

}

export function InputSearch(props) {
    return (
        <div id='searchContainer'>
            <div id='search'>
                <input
                    style={{ width: props.width }}
                    type="text"
                    placeholder={props.placeholder || "Pesquisar..."}
                    value={props.search}
                    onChange={(e) => props.setSearch(e.target.value)}
                >
                </input>
                <div>
                    <IoIosSearch />
                </div>
            </div>
            {props.children}
        </div>
    )
}

export function InputSearch2(props) {
    return (
        <div id='searchContainer'>
            <div id='search'>
                <input
                    style={{ width: props.width }}
                    type="text"
                    placeholder={props.placeholder || "Pesquisar..."}
                    value={props.search}
                    onChange={(e) => props.setSearch(e.target.value)}
                >
                </input>
                <div>
                  
                </div>
            </div>
            {props.children}
        </div>
    )
}

export function InputSearchButton(props) {
    return (
        <div id='searchContainer'>
            <div id='search'>
                <input
                    style={{ width: props.width }}
                    type="text"
                    placeholder={props.placeholder || "Pesquisar..."}
                    value={props.search}
                    onChange={(e) => props.setSearch(e.target.value)}
                >
                </input>
                <button onClick={()=> props.cadastrar()}>
                    Cadastrar
                </button>
            </div>
            {props.children}
        </div>
    )
}