import { useState, useEffect, useContext } from 'react'
import styles from './UserForm.module.css'
import { SelectC, InputMasks, Button, Radio, InputText, InputDateC } from '../../a_model/Input'
import { Context } from '../e_contexts/AuthContext'
import { deletar, get, update } from '../../a_model/fetch'



export default function EditUser({iduser, setIduser}) {
    const { setRecord } = useContext(Context)
    const [project, setProject] = useState({})
    const options = [
        { id: 'feminino', nome: 'Feminino' },
        { id: 'masculino', nome: 'Masculino' },
        { id: 'nao', nome: 'Não informado' }
    ]
    const options2 = [
        { id: 'solteiro', nome: 'Solteira(o)' },
        { id: 'casado', nome: 'Casada(o)' },
        { id: 'divorciado', nome: 'Divorciada(o)' },
        { id: 'viuvo', nome: 'Viúva(o)' }
    ]

    useEffect(() => {
        iduser &&
        get(`/user/${iduser}`, setProject)
    }, [])



    function handleChange(e) {
        setProject({ ...project, [e.target.name]: e.target.value })

    }


    function editPost(id, cadastro) {
        function then(){
           
            window.alert('Atualizado')
        }
        update(`/update/${id}`, cadastro, then)

    }


    return (
        <form className={styles.form}>
            <h1>Cadastro</h1>
            <div className={styles.steps}>
                <div className={styles.step}>
                    <InputText
                        flex='column'
                        width='25em'
                        title="Nome"
                        name="name"
                        value={project.name}
                        placeholder="Insira o nome do paciente..."
                        handleOnChange={handleChange}
                    />
                    <InputMasks
                        flex='column'
                        mask='1'
                        title="CPF"
                        name="cpf"
                        value={project.cpf}
                        placeholder="Insira o CPF do paciente..."
                        handleOnChange={handleChange}

                    />
                    <div className={styles.inline2}>
                        <InputDateC
                            flex='column'
                            title="Data de nascimento"
                            name="nasc"

                            value={project.nasc ? project.nasc.substr(0, 10) : ''}
                            handleOnChange={handleChange}
                        />
                        <InputText
                            flex='column'
                            width='4em'
                            title="Filhos"
                            name="filhos"
                            value={project.filhos}
                            placeholder="Não"
                            handleOnChange={handleChange}
                        />

                    </div>

                    <div className={styles.inline2}>
                        <SelectC
                            flex='column'
                            width='160px'
                            options={options2}
                            name='civil'
                            value={project.civil}
                            text='Estado Civil'
                            handleOnChange={handleChange}
                        />
                        <SelectC
                            padrao='Feminino'
                            flex='column'
                            width='10em'
                            options={options}
                            name='sexo'
                            value={project.sexo}
                            text='Sexo'
                            handleOnChange={handleChange}
                        />


                    </div>
                    <InputText
                        flex='column'
                        width='15em'
                        title="Profissão"
                        name="profissao"
                        value={project.profissao}
                        placeholder="Insira a profissão do paciente..."
                        handleOnChange={handleChange}
                    />

                </div>
                <div className={styles.step}>
                    <InputMasks
                        flex='column'
                        mask='2'
                        title="Telefone"
                        name="tel"
                        value={project.tel}
                        placeholder="Insira o telefone do paciente..."
                        handleOnChange={handleChange}
                    />
                    <InputText
                        flex='column'
                        width='25em'
                        title="Contato de Emergência (Nome)"
                        value={project.contato}
                        name="contato"
                        placeholder="Insira o contato de emergência..."
                        handleOnChange={handleChange}
                    />
                    <InputMasks
                        flex='column'
                        mask='2'
                        title="Contato de Emergência (Telefone)"
                        value={project.etel}
                        name="etel"
                        placeholder="Telefone de emergência..."
                        handleOnChange={handleChange}
                    />
                    <InputText
                        flex='column'
                        width='25em'
                        title="Endereço"
                        name="endereco"
                        value={project.endereco}
                        placeholder="Insira o endereço do paciente..."
                        handleOnChange={handleChange}
                    />
                    <InputText
                        flex='column'
                        width='20em'
                        title="E-mail"
                        name="email"
                        value={project.email}
                        placeholder="Insira o e-mail do paciente..."
                        handleOnChange={handleChange}
                    />

                </div>
            </div>
            <div className={styles.steps}>
                <Button
                    color='#213e6d'
                    value='Salvar'
                    click={() => editPost(iduser, project)}

                />
              

            </div>

        </form>
    )
}