import React, { useState, useEffect, useContext, useMemo } from 'react';
import "./Control.css"
import { MdOutlineDelete } from 'react-icons/md'
import { BsCheck2Square } from 'react-icons/bs'
import { InputMasks, InputText, Button, InputDate, Select, InputFloat, InputTime, InputSearch } from '../../a_model/Input';
import moment from 'moment'
import { Table, Table3, Th } from '../../a_model/Contents';
import { deletar, get, insert, update } from '../../a_model/fetch';
import { Context } from '../e_contexts/AuthContext';
import { Card } from '../../a_model/Container';



function Controls({ iduser, idrecord }) {
  const { params } = useContext(Context)

  const [project, setProject] = useState({
    data: moment().format('YYYY-MM-DD'),
    hora: '',
    valor: '',
    confirm: 0,
    iduser: iduser,
    sessao: 0
  })

  const [sessions, setSessions] = useState([])
  const [count, setCount] = useState([])
  const [cliente, setCliente] = useState(false)


  useEffect(() => {

    getSessions()
  }, [iduser, params, count])

  useEffect(() => {
    setProject({
      data: moment().format('YYYY-MM-DD'),
      hora: moment().format('HH:mm'),
      valor: 0,
      confirm: 0,
      iduser,
      sessao: 0
    })
  }, [sessions])

  function getSessions() {
    get(`/control/${params.confirm}/${params.data}/${params.data_fim}`, then2)
  }

  function then2(resp2) {
    setSessions(resp2)
  }

  function then3() {
    setCount(Math.random())
  }

  function deleteSession(id) {

    var resp = window.confirm("Confirma a exclusão deste registro?");
    if (resp) {

      deletar(`/delete3/${id}`, then3)

    }

  }
  function editConfirm(id) {

    var resp = window.confirm("Confirma pagamento da sessão?");
    if (resp) { update(`/confirm/${id}`, then3) }
  }


  function confirm(id) {
    editConfirm(id)
  }

  const titles = [
    { title: 'Nome' },
    { title: 'Data' },
    { title: 'Valor' },
    { title: 'Pagamento' },
    { title: 'Excluir' },
  ]
  const titles2 = [
    { title: 'Sessão', width:'60px' },
    { title: 'Data' },
    { title: 'Horário' },
    { title: 'Valor' },
    { title: '' },
  ]
  function handleChange(e) {
    setProject({ ...project, [e.target.name]: e.target.value })
  }

  return (

      <Table>
        <Th array={titles} />
        {sessions.map(session => {
          let inicion = session.data.substr(0, 10).split('-').reverse().join('/');
          return (
            <tr id="" key={sessions.indexOf(session)} className='' >
              <td className='clickControl' onClick={() => window.location.replace(`/prontuario/${session.iduser}/${session.idrecord}/2`)}>{(session.name || '-')}</td>

              <td className=''>{(inicion || '-')}</td>

              <td className=''>{(`R$${session.valor},00` || '-')}</td>
              <td className='tdConfirm'>{session.confirm == '0' ?
                <Button
                  color='#213e6d'
                  value='Confirmar'

                  click={() => confirm(session.id)}
                />

                : <BsCheck2Square />}</td>
              <td className='dele' onClick={() => deleteSession(session.id)}>

                <MdOutlineDelete />

              </td>
            </tr>
          )
        })}

      </Table>

  );
} export default Controls;
/**
 *   <div className='dashControls'>
           <div className='dashControl'>
             <label>Atendimentos</label>
             <p>Pagos: {count.pago}</p>
             <p>Pendentes: {parseInt(count.total) - parseInt(count.pago)}</p>
             <p>Total: {count.total}</p>

           </div>
           {count.total ?
             <div className='dashControl'>

               <label>Caixa</label>
               <p>Confirmado: {parseInt(count.valor_pago).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
               <p>Pendente: {(parseInt(count.valor_total) - parseInt(count.valor_pago)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
               <p>Valor Total: {count.valor_total && parseInt(count.valor_total).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
             </div>
             : ''}
         </div>
 */