import React, { useState, useEffect } from 'react';
import "./Resumo.css"
import InfoCard from '../c_layouts/SupervisaoCard';
import { Context } from '../e_contexts/AuthContext';
import { useContext } from 'react';
import { Button, InputMasks, InputDate, InputText, TextArea, InputTime } from '../../a_model/Input'
import moment from 'moment';
import { Card } from '../../a_model/Container'
import { deletar, get, insert, update } from '../../a_model/fetch';
import { Table3 } from '../../a_model/Contents';
import ConceitoCard from '../c_layouts/ConceitoCard';

function SupervisaoForm() {
  
    const { iduser } = useContext(Context)
    const [sessions, setSessions] = useState([])
    const [project, setProject] = useState({
        data: moment().format('YYYY-MM-DD'),
        hora: moment().format('HH:mm'),
        iduser,
        session: ''
    })

    useEffect(() => {
        getSessions()

    }, [])

    function getSessions() {
        setProject({
            data: moment().format('YYYY-MM-DD'),
            hora: moment().format('HH:mm'),
            iduser,
            session: ''
        })
        get(`/supervisao/${iduser}`, setSessions)
    }


    function handleChange(e) {
        setProject({ ...project, [e.target.name]: e.target.value })



    }

    const titles2 = [
        { title: 'Sessão', width:'60px' },
        { title: 'Data' },
        { title: '' },
    ]

    return (
        <div className="evoPanel">
        <div>
            <Card
                width='100%'
                header={
                    <Table3
                    header={titles2}
                    >
                        
                        <tr>
                            <td>
                                <InputText
                                    width='50px'
                                    value={project.sessao || ''}
                                    name='sessao'
                                    handleOnChange={handleChange}
                                />

                            </td>
                            <td>
                                <InputDate

                                    name="data"
                                    value={project.data}
                                    handleOnChange={handleChange}
                                />

                            </td>
                        
                            <td>
                                <Button
                                    color='#447461'
                                    value='Registrar'
                                    click={() => insert(`/supervisao`, project, getSessions)}
                                />
                            </td>

                        </tr>
                    </Table3>
                }
            >
                <div style={{ width: '90%' }}>
                    <TextArea
                        name='supervisao'
                        value={project.supervisao || ''}
                        handleOnChange={handleChange}
                        title="Supervisão"
                    />
                  
                </div>
            </Card>
        </div>
        <div>
            <ConceitoCard iduser={iduser}/>
            <InfoCard
                sessions={sessions}
                getSessions={getSessions}
                iduser={iduser}

            />
        </div>

    </div>
    );
}

export default SupervisaoForm