import React, { useEffect, useState } from 'react';
import { MdOutlineDelete } from 'react-icons/md'
import { AiOutlineEdit } from 'react-icons/ai'
import { BiEdit } from 'react-icons/bi'
import { Button, InputDate, InputText, TextArea, InputTime } from '../../a_model/Input'
import { Card } from '../../a_model/Container';
import { deletar, get, update } from '../../a_model/fetch';
import { toDate } from '../../a_model/functions';
import { Table, Th } from '../../a_model/Contents';

export default function ConceitoCard({ iduser }) {

    const [sessionC, setSessionC] = useState(false)
    useEffect(() => {
        !sessionC &&
            getSessionC()
    }, [])
    const [editaC, setEditaC] = useState(0)
    function getSessionC() {
        setEditaC(0)
        get(`/conceitualizacao/${iduser}`, setSessionC)

    }
    const forms = [
        <InfoConceito setEditaC={setEditaC} sessionC={sessionC} />,
        <EditConceito setEditaC={setEditaC} sessionC={sessionC} getSessionC={getSessionC} />
    ]


    return (

        <div className="sessoes">
            {
                sessionC &&
                <div>
                    {forms[editaC]}
                </div>
            }
        </div>
    )
}

function InfoConceito({ sessionC, setEditaC }) {

    return (
        <Card>
            <div className='session' key={sessionC.id}>

                <label>Nome Fictício: <span className="value"> {sessionC.nome_ficticio}</span></label>
                <label>Estrutura: <span className="value"> {sessionC.estrutura}</span></label>
                <label>Conceitualização: <span className="value"> {sessionC.text}</span><div

                    id={sessionC.id}
                    onClick={() => setEditaC(1)}

                >
                    <BiEdit />
                </div></label>

            </div>
        </Card>
    )
}

function EditConceito({ sessionC, getSessionC, setEditaC }) {
    const [project, setProject] = useState(sessionC)

    function handleChange(e) {
        setProject({ ...project, [e.target.name]: e.target.value })

    }
    function edit() {
        update(`/update7`, project, getSessionC)
    }
    const titles2 = [

        { title: '*' },
        { title: '*' },
    ]

    return (
        <Card
            header={
                <>
                    <Table>
                        <Th array={titles2} />
                        <tr>
                            <td>
                                <Button
                                    color='#2c61c4'
                                    value='Salvar'
                                    click={() => edit(sessionC.id, project)}
                                />
                            </td>
                            <td>
                                <Button
                                    color='#554'
                                    value='Cancelar'
                                    click={() => setEditaC(0)}
                                />
                            </td>
                        </tr>
                    </Table>
                </>
            }>
            <div style={{ width: '90%' }}>
                <InputText

                    name='nome_ficticio'
                    value={project.nome_ficticio || ''}
                    handleOnChange={handleChange}
                    title="Nome Fictício"
                />
                <TextArea

                    name='estrutura'
                    value={project.estrutura || ''}
                    handleOnChange={handleChange}
                    title="Estrutura"
                />
                <TextArea

                    name='text'
                    value={project.text || ''}
                    handleOnChange={handleChange}
                    title="Conceito"
                />

            </div>
        </Card>
    )

}