import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { AuthProvider } from './integral/e_contexts/AuthContext';
import Home from './integral/a_pages/Home';
import Login from './integral/a_pages/Login';

export default function App3() {
  return (
    <AuthProvider>
      <Routes>
        <Route exact path='/home' element={<Home />} />
        <Route exact path='/login' element={<Login />} />
      </Routes>
    </AuthProvider>
  );
}
