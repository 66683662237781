import React, { useState } from "react";
import './Inicio.css'
import Cadastrar from "./Cadastrar";
import Tabela from "./Tabela";
import Selecionar from "./Selecionar";


export default function Inicio() {
    const [step, setStep] = useState(0)
    const [id_copinha, setId_copinha] = useState(false)
    function Home(){
        return(
            <>
            <button onClick={()=> setStep(1)}>
                Cadastrar
            </button>
            <button onClick={()=> setStep(3)}>
                Selecionar
            </button>
          
            </>
        )
    }
    const forms = [
        <Home/>,
        <Cadastrar setId_copinha={setId_copinha} setStep={setStep}/>,
        <Tabela id_copinha={id_copinha} setId_copinha={setId_copinha} setStep={setStep}/>,
        <Selecionar setId_copinha={setId_copinha} setStep={setStep}/>
    ]

    return (
        <div id='copinhaContainer'>

            {forms[step]}
        </div>
    )
}