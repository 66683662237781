// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Container_container__fw4Yw{\r\n    display: flex;\r\n    justify-content: space-between;\r\n    margin: 0 auto;\r\n    flex-wrap: wrap;\r\n    \r\n}\r\n\r\n.Container_min-height__B6UVD{\r\n    align-items: center;\r\n    justify-content: center;\r\n    padding: 0;\r\n    min-width: 100%;\r\n    overflow: hidden;\r\n}\r\n\r\n.Container_start__8fqiO{\r\n    justify-content: flex-start;\r\n}\r\n\r\n.Container_column__8uepT {\r\n    flex-direction: column;\r\n    justify-content: flex-start;\r\n}", "",{"version":3,"sources":["webpack://./src/components/c_layouts/Container.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,cAAc;IACd,eAAe;;AAEnB;;AAEA;IACI,mBAAmB;IACnB,uBAAuB;IACvB,UAAU;IACV,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,sBAAsB;IACtB,2BAA2B;AAC/B","sourcesContent":[".container{\r\n    display: flex;\r\n    justify-content: space-between;\r\n    margin: 0 auto;\r\n    flex-wrap: wrap;\r\n    \r\n}\r\n\r\n.min-height{\r\n    align-items: center;\r\n    justify-content: center;\r\n    padding: 0;\r\n    min-width: 100%;\r\n    overflow: hidden;\r\n}\r\n\r\n.start{\r\n    justify-content: flex-start;\r\n}\r\n\r\n.column {\r\n    flex-direction: column;\r\n    justify-content: flex-start;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Container_container__fw4Yw",
	"min-height": "Container_min-height__B6UVD",
	"start": "Container_start__8fqiO",
	"column": "Container_column__8uepT"
};
export default ___CSS_LOADER_EXPORT___;
