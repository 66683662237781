import React, { useEffect, useState } from 'react';
import css from './Geral.module.scss'
import { Context } from '../e_contexts/AuthContext';
import { useContext } from 'react';
import { toDate } from '../../a_model/functions'
import { FiEdit } from 'react-icons/fi'
import { LabelText } from '../../a_model/Input';
import { get } from '../../a_model/fetch';

function Geral() {
    const { iduser, setStep } = useContext(Context)
    const [record, setRecord] = useState(false)
    useEffect(() => {
        get(`/record/${iduser}`, setRecord )
    }, [])
    
    return (
        record &&
        <div className={css.geralContainer}>
            <header>
                <LabelText
                    header='Prontuário nº'
                    content={record.num || '?'} />
                <LabelText
                    header='Data de início'
                    content={toDate(record.inicio)} />
            </header>
            <body>
                <div >
                    <h1>Atendimento / Histórico</h1>
                    <div>
                        <div>
                            <LabelText content={record.aa}
                                flex='column' header='Queixa Principal:' />

                            <LabelText header='Medicamentos utilizados: '
                                content={record.ab} />

                            <LabelText header='Outros Tratamentos: '
                                content={record.ac}
                            />
                            <LabelText header='Observação:' content={record.obs}
                            />
                        </div>
                    </div>
                </div>

                <div >
                    <h1>Exame Psíquico</h1>
                    <div>
                        <div>
                            <LabelText content={record.ba}
                                header=' a) Aparência :' />
                            <LabelText content={record.bb}
                                header='b) Comportamento:' />
                            <LabelText content={record.bc}
                                header='c) Atitude para com o entrevistador:' />
                            <LabelText
                                header='d) Orientaçao:'
                                content={
                                    record.bd.map((bd, index) =>
                                        bd + (index < record.bd.length - 1 ? ', ' : '')
                                    )
                                } />





                            <LabelText content=
                                {record.be} header='e) Atenção:' />
                            <LabelText content={record.bf} header='f) Tenacidade:' />
                            <LabelText content={record.bg} header='g) Discurso:' />
                        </div>


                        <div>
                            <LabelText content={record.bh} header='h) Sensopercepção:' />
                            <LabelText content=
                                {record.bi} header='i) Pensamento:' />
                            <LabelText content={record.bj} header='j) Linguagem:' />
                            <LabelText content={record.bl} header='l) Humor:' />
                            <LabelText header='k) Afetividade:'
                                content={
                                    record.bk.map((bk, index) =>
                                        bk + (index < record.bk.length - 1 ? ', ' : '')
                                    )
                                } />
                            <LabelText content={record.bm}
                                header='m) Consciência da  doença atual:' />
                        </div >
                    </div>
                </div>
                <div>
                    <h1>Hipótese Diagnóstica</h1>
                    <div>
                        <div>
                            <LabelText content={record.ca} />
                        </div>
                    </div>
                </div>
            </body >
            <button
                type='button'
                className={css.editInfo}
                onClick={() => setStep(6)}
            >
                <span><FiEdit />Editar</span>
            </button>
        </div >
    );
}

export default Geral